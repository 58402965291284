import React, { useEffect, useState, useContext } from 'react'
import dayjs from 'dayjs'
import { Text, Box, Avatar, Input, Button } from '@chakra-ui/react'
import BoxSummary from '../../components/Dashboard/BoxSummary'
import { get_all_today_transactions, get_dashboard_inventory, get_dashboard_total_payments_by_methods, get_dashboard_users } from '../../utils/apis/dashboard';
import { get_all_orders } from '../../utils/apis/orders';
import DailyRow from '../../components/Reports/Daily/DailyRow';
import { SocketContext } from '../../contexts/Socket/socket';

const Dashboard = () => {
  const socket = useContext(SocketContext)
  const today = dayjs().format('DD/MM/YYYY');
  const [dateStart, setDateStart] = useState(null);
  const [completeOrders, setCompleteOrders] = useState()
  const [totalEarn, setTotalEarn] = useState(0.00);
  const [totalClosed, setTotalClosed] = useState(0);
  const [totalOpen, setTotalOpen] = useState(0);
  const [byUser, setByUser] = useState();
  const [methodAmount, setMethodAmount] = useState({
    efectivo: 0,
    tarjeta: 0
  });
  const [products, setProducts] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);


  const getTotalProducts = async (items) => {
    let total = 0;

    for (let i = 0; i < items.length; i++) {
      total -= items[i].qty
    }
    return total;
  }

  const getDashboard = async (date) => {
    await getDashboardTransactions(date);
    await getDashboardInventory(date);
    await getDashboardTotalPaymentsByMethods(date);
    await getDashboardUsers(date);
  }

  useEffect(() => {
    (async () => {
      setDateStart(dayjs().format('YYYY-MM-DD'))
      await getDashboard(dayjs().format('YYYY-MM-DD'))
    })()
  }, [])

  const getDashboardTransactions = async (start) => {
    const res = await get_all_today_transactions({ dateStart: dayjs(start).set('hour', 6).set('minute', 0).set('second', 0).toISOString() });
    const resOrder = await get_all_orders();
    setCompleteOrders(res.results.transactions);
    setTotalEarn(res.results.total)
    setTotalClosed(res.results.totalTransactions)
    setTotalOpen(resOrder.results.total);
  }
  const getDashboardInventory = async (start) => {
    const res = await get_dashboard_inventory({ dateStart: dayjs(start).set('hour', 6).set('minute', 0).set('second', 0).toISOString() });
    setProducts(res.results.inventory)
    const total = await getTotalProducts(res.results.inventory)
    setTotalProducts(total)
  }

  const getDashboardTotalPaymentsByMethods = async (start) => {
    const res = await get_dashboard_total_payments_by_methods({ dateStart: dayjs(start).set('hour', 6).set('minute', 0).set('second', 0).toISOString() });
    const efectivo = res.results.payments.filter(item => {
      return item._id === 'efectivo'
    })
    const tarjeta = res.results.payments.filter(item => {
      return item._id === 'tarjeta'
    })

    setMethodAmount({ efectivo: efectivo[0]?.total, tarjeta: tarjeta[0]?.total })

  }

  const getDashboardUsers = async (start) => {
    const res = await get_dashboard_users({ dateStart: dayjs(start).set('hour', 6).set('minute', 0).set('second', 0).toISOString() })
    setByUser(res.results.users)
  }

  useEffect(() => {
    socket.on('refreshDashboard', async (payload) => {
      console.log(payload)
      await getDashboard(dateStart)
    })



  }, [socket])


  return (
    <div className='flex flex-col mb-4'>
      <div className='flex flex-col md:flex-row gap-1 justify-between items-stretch md:items-end mb-4'>
        <Text className="text-3xl font-bold my-2 ">Dashboard</Text>
        <Box className='flex flex-col md:flex-row gap-1 items-stretch md:items-center'>
          <Box className='flex flex-row gap-1 items-center'>
            <Text>De: </Text>
            <Input type='date' value={dateStart} onChange={(e) => setDateStart(e.target.value)} />
          </Box>

          <Button variant='outline' colorScheme='teal' onClick={() => getDashboard(dateStart)}>Buscar</Button>
        </Box>
      </div>

      <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-2 mb-2'>
        <BoxSummary title='Abiertas' value={totalOpen ? totalOpen : '0'} color='green' />
        <BoxSummary title='Cerradas' value={totalClosed} color='teal' />
        <BoxSummary title='Productos vendidos' value={totalProducts} color='purple' />
        <BoxSummary title={'Efectivo'} value={methodAmount.efectivo ? `$ ${methodAmount.efectivo.toFixed(2)}` : '$ 0.00'} color='orange' />
        <BoxSummary title={'Tarjeta'} value={methodAmount.tarjeta ? `$ ${methodAmount.tarjeta.toFixed(2)}` : '$ 0.00'} color='red' />
        <BoxSummary title='Total de ventas' value={totalEarn ? `$ ${totalEarn.toFixed(2)}` : '$ 0.00'} color='blue' />

      </div>
      <div className='flex flex-col-reverse md:flex-row justify-between gap-2'>

        <Box className='w-12/12 md:w-7/12' p={4} borderWidth='1px' borderRadius='lg'>
          <Text className='font-bold text-lg uppercase'>Ventas del día</Text>
          {completeOrders ?
            completeOrders.map(order => (

              <DailyRow key={order._id} order={order} />

            )) : null}
        </Box>
        <Box className='w-12/12 md:w-5/12 flex flex-col gap-2'>
          <Box className='w-12/12' p={4} borderWidth='1px' borderRadius='lg'>
            <Text className='font-bold text-lg uppercase'>Ventas por usuario</Text>
            {
              byUser ? byUser.map(user => (
                <Box key={user._id} className='grid grid-cols-2 gap-1 border-b border-gray-300 last:border-0' w='100%' py={4}>
                  <Text noOfLines={1} className='text-sm md:text-lg'>{user.name}</Text>
                  <Text className='text-sm md:text-lg font-bold text-right'>${user.total.toFixed(2)}</Text>

                </Box>
              )) :
                null
            }
          </Box>
          <Box className='w-12/12' p={4} borderWidth='1px' borderRadius='lg'>
            <Text className='font-bold text-lg uppercase'>Productos vendidos</Text>
            {
              products ? products.map(product => (
                <Box key={product._id} className='grid grid-cols-2 gap-1 border-b border-gray-300 last:border-0' w='100%' py={4}>
                  <Text noOfLines={1}>{(product.qty * -1)} - {product.name}</Text>
                  <Text className='font-bold text-right'>${((product.qty * -1) * product.price).toFixed(2)}</Text>

                </Box>
              )) :
                null
            }
          </Box>
        </Box>
      </div>


    </div>
  )
}

export default Dashboard