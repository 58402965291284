import React, { useState } from 'react'
import { Input, Button, Text, Box, Divider, Avatar, IconButton, Tooltip, Tag } from '@chakra-ui/react';
import { MdOutlineDeliveryDining, MdOutlineShoppingBag, MdOutlineChairAlt } from 'react-icons/md'
import { FaTags, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import dayjs from 'dayjs';

const DailyRow = ({ order }) =>
{
  const [ show, setShow ] = useState(false);

  return (
    <Box className='w-full border rounded-lg my-2 px-2 py-2 print:py-0'>
      <Box className='flex flex-row justify-between items-center'>
        <Box>
            <Tag colorScheme={order.methodPayment ==='efectivo' ? 'green': 'blue'} className='text-sm capitalize'>{order.methodPayment}</Tag>
        </Box>
        <Tooltip label={ show ? 'Cerrar' : 'Abrir' }>
          <IconButton size='sm' icon={ show ? <FaChevronUp /> : <FaChevronDown /> } onClick={ () => setShow(!show) } />
        </Tooltip>
      </Box>
      <Box className='grid grid-cols-1 md:grid-cols-2  print:grid-cols-2 place-items-between gap-2 w-full mt-2'>
        <Text className='text-sm' noOfLines={ 1 }>{ dayjs(order.dateTransaction).format('DD MMM YYYY, HH:mm') }</Text>
        <Box className='grid grid-cols-1 print:grid-cols-2  md:grid-cols-2 gap-1 md:place-items-end'>
          <Box className='flex flex-row justify-start items-center gap-1'>
            <Text fontSize='x-small' noOfLines={ 1 }>Cobrado por:</Text>
            <Avatar name={ order.user.name } size='xs' />
            <Text fontSize='x-small' noOfLines={ 1 }>{ order.user.name }</Text>
          </Box>
          <Box className='flex flex-row justify-start items-center gap-2'>
            <Text fontSize='x-small' noOfLines={ 1 }>Atendido por:</Text>
            <Avatar name={ order.waiter?.name } size='xs' />
            <Text fontSize='x-small' noOfLines={ 1 }>{ order.waiter?.name }</Text>
          </Box>
        </Box>

      </Box>
      <Box key={ order._id } w='100%' className='h-14 print:flex-row flex flex-row justify-between items-center  px-1 md:px-4'>
        <Box className='grid grid-col-1 print:flex print:flex-row sm:flex sm:flex-row gap-2 items-center'>
          <Box className='flex flex-row gap-1 items-center'>
            { order.orderType === 'local' && <MdOutlineChairAlt className='text-lg md:text-2xl' /> }
            { order.orderType === 'delivery' && <MdOutlineDeliveryDining className='text-lg md:text-2xl' /> }
            { order.orderType === 'pickup' && <MdOutlineShoppingBag className='text-md md:text-xl' /> }
            { order.orderType === 'local' && <Text className='text-sm md:text-md mx-1 md:mx-2' noOfLines={ 2 } >{ order.orderInfo.table }</Text> }
            { order.orderType === 'delivery' && <Text className=' text-sm md:text-md mx-1 md:mx-2' noOfLines={ 2 } >Para entregar</Text> }
            { order.orderType === 'pickup' && <Text className=' text-sm md:text-md mx-1 md:mx-2' noOfLines={ 2 } >Para llevar</Text> }
          </Box>
        </Box>
        <Box>
          <Text>{ order.orderInfo?.name }</Text>
        </Box>


      </Box>
      <Divider className='my-2' />
      {/* {show && <Box className='py-2'>
          <Text className='text-sm border-b py-2'>Tel: {order.orderInfo.phone}</Text>
          <Text className='text-sm border-b py-2'>Dirección: {order.orderInfo.address}</Text>
          <Text className='text-sm border-b py-2'>Personas: {order.orderInfo.persons}</Text>
          <Text className='text-sm border-b py-2'>Fecha: {order.orderInfo.pickUpDate}</Text>
          <Text className='text-sm border-b py-2'>Hora: {order.orderInfo.pickUpTime}</Text>

      </Box>} */}
      <Box className='flex flex-row gap-2 items-center px-1 md:px-4 '>
        <FaTags className='text-lg md:text-2xl' />
        <Text className=' text-sm md:text-md mx-1 md:mx-2' noOfLines={ 1 }>{ order.orderProducts.length } productos</Text>
      </Box>
      {
        show && <Box>
          { order.orderProducts ? order.orderProducts.map(item => (
            <Box className='my-2 border-b last:border-b-0 p-2 flex flex-row justify-between'>
              <Box className='flex flex-row gap-2 items-baseline'>
                <Text className='text-xs'> { item.quantity } x</Text>
                <Text> { item._id.name }</Text>
              </Box>
              <Text>$ { (item.price * item.quantity).toFixed(2) }</Text>
            </Box>
          )) : null }
        </Box>
      }
      <Divider className='my-2' />
      <Box className='grid grid-cols-1'>
        { show && <Box className='flex flex-row justify-end gap-2 items-center'>
          <Text className='text-sm'>Subtotal</Text>
          <Text className='text-md md:text-md font-bold text-right' noOfLines={ 1 }>${ order?.subtotal.toFixed(2) }</Text>
        </Box> }
        { show && <Box className='flex flex-row justify-end gap-2 items-center'>
          <Text className='text-sm'>Desc.</Text>
          <Text className='text-md md:text-md font-bold text-right' noOfLines={ 1 }>${ order?.discount.toFixed(2) }</Text>
        </Box> }
        { show && <Box className='flex flex-row justify-end gap-2 items-center'>
          <Text className='text-sm'>IVA</Text>
          <Text className='text-md md:text-md font-bold text-right' noOfLines={ 1 }>${ order?.tax.toFixed(2) }</Text>
        </Box> }
        <Box className='flex flex-row justify-end gap-2 items-center'>
          <Text className='text-sm'>Total</Text>
          <Text className='text-md md:text-md font-bold text-right' noOfLines={ 1 }>${ order?.total.toFixed(2) }</Text>
        </Box>
      </Box>
    </Box>
  )
}

export default DailyRow