import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Input, InputLeftAddon, InputGroup, Text, Stack, Textarea, Select, IconButton, Button, Checkbox } from '@chakra-ui/react'
import { HiArrowNarrowLeft, HiMinus, HiPlus } from "react-icons/hi";
import { get_product, post_product, update_product } from '../../../utils/apis/products';
import { get_categories } from '../../../utils/apis/categories';
import { useFormik } from 'formik'
import * as Yup from 'yup'

const Product = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const [_id, setId] = useState(null)
  const [preview, setPreview] = useState(null)
  let { id } = useParams()

  useEffect(() => {
    (async () => {
      const resCat = await get_categories();
      setCategories(resCat.results)
      if (id) {
        setId(id)
        const resProduct = await get_product(id);
        formik.setFieldValue('name', resProduct.results.name);
        formik.setFieldValue('description', resProduct.results.description);
        formik.setFieldValue('price', resProduct.results.price);
        formik.setFieldValue('qty', resProduct.results.qty);
        formik.setFieldValue('status', resProduct.results.status === true ? 'visible' : 'hide')
        formik.setFieldValue('inventoryStatus', resProduct.results.inventoryStatus === true ? 'si' : 'no')
        formik.setFieldValue('category', resProduct.results.category);
        setPreview(resProduct.results.image)

      }
    })()
  }, [])


  const addToInventory = (e) => {
    console.log(typeof formik.values.qty)
    formik.setFieldValue('qty', formik.values.qty + 1)
  }
  const removeToInventory = (e) => {
    formik.setFieldValue('qty', formik.values.qty - 1)
  }
  const resteFormikValue = async () => {
    formik.resetForm()
  }
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      console.log(data)
      const product = {
        name: data.name,
        description: data.description,
        price: data.price,
        category: data.category,
        qty: data.qty,
        status: data.status === 'visible' ? true : false,
        inventoryStatus: data.inventoryStatus === 'si' ? true : false,
        image: data.image
      }
      if(_id){
        product._id = _id
        await update_product(_id,product)
      }else{
        await post_product(product)
      }
      formik.resetForm()
      navigate(-1)
     
    }
  })
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col md:flex-row items-center justify-between gap-3'>
        <div className='flex flex-row items-center justify-start gap-2 w-full md:w-auto'>
          <IconButton aria-label='Regresar' icon={<HiArrowNarrowLeft />} onClick={() => navigate(-1)} />
          <Text className='text-xl md:text-2xl' as='b'>{_id ? formik.values.name : 'Crear un producto'}</Text>
        </div>
        <div className='flex flex-col md:flex-row gap-2 w-full md:w-auto '>
          <Button colorScheme='gray' onClick={() => navigate(-1)} >Descartar</Button>
          <Button colorScheme='green' onClick={() => formik.handleSubmit()}>Guardar</Button>
        </div>
      </div>
      <div className='flex flex-col md:flex-row gap-4'>
        <div className='w-12/12 md:w-8/12'>
          <Box w='100%' p={4} borderWidth='1px' borderRadius='lg' >
            <Stack>
              <div>
                <Text mb='8px' fontSize='lg'>Nombre</Text>
                <Input placeholder='Nombre del producto' value={formik.values.name} onChange={(e) => formik.setFieldValue('name', e.target.value)} />
              </div>
              <div>
                <Text mb='8px' fontSize='lg'>Descripción</Text>
                <Textarea placeholder='' value={formik.values.description} onChange={(e) => formik.setFieldValue('description', e.target.value)} />
              </div>
            </Stack>
          </Box>
          <Box w='100%' p={4} mt={2} borderWidth='1px' borderRadius='lg'>
            <Stack>
              <div className='flex flex-col md:flex-row gap-2 justify-between items-start md:items-center'>

                <Text mb='8px' fontSize='lg'>Precio de venta</Text>
                <div className='flex flex-row gap-1'>
                  <InputGroup>
                    <InputLeftAddon children='$' />
                    <Input placeholder='0' type='number' value={formik.values.price} onChange={(e) => formik.setFieldValue('price', Number(e.target.value))} />
                  </InputGroup>


                </div>
              </div>
            </Stack>
          </Box>
          <Box w='100%' p={4} mt={2} borderWidth='1px' borderRadius='lg'>
            <Stack>
              <Text mb='8px' fontSize='lg'>Inventario</Text>
              <div className='flex flex-col md:grid md:grid-cols-2 gap-2 justify-between items-start md:items-center'>
                <div className='w-full'>
                  <Text mb='8px' fontSize='lg'>Dar seguimiento</Text>
                  <Select placeholder=' ' value={formik.values.inventoryStatus} onChange={(e) => formik.setFieldValue('inventoryStatus', e.target.value === 'si' ? 'si' : 'no')}>
                    <option value='si'>Si</option>
                    <option value='no'>No</option>
                  </Select>
                </div>
                {formik.values.inventoryStatus === 'si' && <div className='w-full'>
                  <Text mb='8px' fontSize='lg'>Cantidad</Text>
                  <div className='flex flex-row gap-1'>
                    <IconButton aria-label='Restar' icon={<HiMinus />} onClick={removeToInventory} disabled={formik.values.qty === 0 ? true : false} />
                    <Input placeholder='' type='number' value={formik.values.qty} onChange={(e) => formik.setFieldValue('qty', Number(e.target.value))} />
                    <IconButton aria-label='Agregar' icon={<HiPlus />} onClick={addToInventory} />
                  </div>
                </div>}
              </div>
            </Stack>
          </Box>
        </div>
        <div className='w-12/12 md:w-4/12 flex flex-col gap-4'>
          <Box w='100%' p={4} borderWidth='1px' borderRadius='lg' >
            <Text mb='8px' fontSize='lg'>Visibilidad</Text>
            <Select placeholder=' ' value={formik.values.status} onChange={(e) => formik.setFieldValue('status', e.target.value === 'visible' ? 'visible' : 'hide')}>
              <option value='visible'>Visible</option>
              <option value='hide'>Ocultar</option>
            </Select>
          </Box>
          <Box w='100%' p={4} borderWidth='1px' borderRadius='lg' >
            <Text mb='8px' fontSize='lg'>Categoria</Text>
            <Select placeholder=' ' value={formik.values.category} onChange={(e) => formik.setFieldValue('category', e.target.value)}>
              {categories && categories.map((category) => (
                <option key={category._id} value={category._id}>{category.name}</option>
              ))}
            </Select>
          </Box>
          {/* <Box w='100%' p={4} borderWidth='1px' borderRadius='lg' >
            <Text mb='8px' fontSize='lg'>Imagen del producto</Text>
            <Input size='lg' p={2} type='file' value={formik.values.image} onChange={(e) => formik.setFieldValue('image', e.target.value)} />
            {preview &&
              <Box className='flex flex-row items-center justify-center my-2'>
                <img src={preview} alt='imagen' className='w-32 h-32 object-fill' />
              </Box>}
          </Box> */}
        </div>
      </div>

    </div>
  )
}

export default Product;

function initialValues () {
  return {
    name: '',
    status: 'visible',
    image: '',
    inventoryStatus: 'no',
    qty: 0,
    description: '',
    price: '',
    category: ''
  }
}

function validationSchema () {
  return {
    name: Yup.string().required(true),
    status: Yup.string(),
    image: Yup.string(),
    inventoryStatus: Yup.string(),
    qty: Yup.number(),
    description: Yup.string(),
    price: Yup.number().required(true),
    category: Yup.string().required(true)


  }
}