
export const get_inventory = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/v1/api/reports/inventory?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const send_inventory_report = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/v1/api/reports/send/inventory?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const get_sales = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/v1/api/reports/sales?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const send_sales_report = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/v1/api/reports/send/sales?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}