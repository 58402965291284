import React from 'react'
import { Box, Alert,AlertIcon,Text } from '@chakra-ui/react'

export const Alerta = ({alerta, title, colorMode}) => {
  return (
    <Box className='flex flex-row w-6/12 lg:w-4/12 absolute bottom-1 right-1 z-10'>
    {alerta ?
        <Box className='w-full'>
            <Alert status={alerta.categoria} variant={colorMode === 'light' ? 'left-accent' : 'solid'}>
                <AlertIcon />
                <Box>
                    <Text className='text-md md:text-lg font-semibold'>{alerta.msg === 'Desconectado' ? 'Ocurrio un error': 'Bienvenido'}</Text>
                    <Text className='text-md md:text-lg font-normal'>{alerta.msg}</Text>
                </Box>
            </Alert>
        </Box>
        : null}
</Box>
  )
}
