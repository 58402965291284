import { Divider, Text, Box, Input, InputGroup, InputRightAddon, Button } from '@chakra-ui/react'
import React, { useState, useEffect, useCallback } from 'react'
import OrderResumenRow from './OrderResumenRow'
import { getTotal } from '../../utils/functions/getTotal'
import Loading from '../Loading'

const OrderResume = ({ order, summary, setSummary }) => {



  const [desc, setDesc] = useState(0);


  const calcTotal = async () => {
    // setGranTotal(subTotal - desc);
    // setIva((granTotal - (granTotal / 1.16)).toFixed(2));
  }


  return (
    <>
      {order.length > 0 ?
        <Box className='w-12/12' p={2} mt={2} borderWidth='1px' borderRadius='lg'>
          <Text className='text-xl font-bold mb-2'>Productos</Text>
          <div className='w-full h-full'>
            {
              order ? order.map(item => (
                <OrderResumenRow key={item._id} product={item} />
              ))
                : null
            }




          </div>
        </Box>
        :
        <Box className='w-full items-center flex flex-row justify-center' p={2} mt={2} borderWidth='1px' borderRadius='lg'>
          <Loading />
        </Box>
      }



    </>
  )
}

export default OrderResume