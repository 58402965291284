/* eslint-disable import/no-anonymous-default-export */
import
    {
        SUCCESSFUL_SIGNIN,
        ERROR_SIGNIN,
        GET_USER_INFO,
        SUCCESSFUL_LOGIN,
        ERROR_LOGIN,
        LOGOUT,
        BLOCK,
        GENERATE_RECOVERY
    } from '../types';



export default (state, action) =>
{
    //console.log(action)
    switch (action.type) {

        case GENERATE_RECOVERY:
            return {
                ...state,
                token: null,
                user: null,
                autenticado: null,
                message: null
            }

        case LOGOUT:
        case ERROR_LOGIN:
        case ERROR_SIGNIN:
            localStorage.clear()
            return {
                ...state,
                token: null,
                user: null,
                autenticado: null,
                message: action.payload
            }
        case BLOCK:
            localStorage.removeItem('token')
            return {
                ...state,
                token: null,
            }
        case SUCCESSFUL_SIGNIN:
        case SUCCESSFUL_LOGIN:
            localStorage.setItem('token', action.payload.token);

            return {
                ...state,
                autenticado: true,
                message: null,
                token: action.payload.token
            }
        case GET_USER_INFO:
            localStorage.setItem('user', JSON.stringify(action.payload));
            return {
                ...state,
                user: action.payload,
                message: null,

            }

        default:
            console.log('default')
            return state;

    }
}