
import AppRoute from "./routes/AppRoute";
import AlertState from './contexts/Alert/alertState'
import AuthState from "./contexts/Auth/authState";
import UserState from "./contexts/Users/userState";

function App () {
  return (

    <AlertState>
      <AuthState>
        <UserState>
          <AppRoute />
        </UserState>
      </AuthState>
    </AlertState>


  );
}

export default App;
