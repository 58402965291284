/* eslint-disable import/no-anonymous-default-export */
import { useReducer } from 'react';
import alertReducer from './alertReducer';
import alertContext from './alertContext'
import {
    SHOW_ALERT,
    HIDE_ALERT
} from '../types';

const AlertState = props => {
    const initialState = {
        alerta:null
    }

    const [state, dispatch] = useReducer(alertReducer, initialState);

    const showAlert = (title, msg, categoria) => {
        dispatch({
            type: SHOW_ALERT,
            payload: {
                title,
                msg,
                categoria
            }
        });

        setTimeout(() => {
            dispatch({
            type: HIDE_ALERT
        });
        }, 5000);
    }
    
    return (
        <alertContext.Provider
            value={{
                alerta: state.alerta,
                showAlert
        }} 
        >
            {
                props.children
            }
        </alertContext.Provider>
    )

}


export default AlertState
