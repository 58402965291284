import React, { useContext, useState, useEffect } from 'react'
import UserContext from '../../contexts/Users/userContext';
import { Text, Button } from '@chakra-ui/react';
import UserTable from '../../components/Users/UserTable';
import ModalUser from '../../components/Modal/ModalUser';
import AuthContext from '../../contexts/Auth/authContext'
const Users = () => {
  const userContext = useContext(UserContext)
  const authContext = useContext(AuthContext);
  const {user} = authContext;
  const { total, list, loading, message, getUsersList, deleteUser, restoreUser } = userContext;
  const [showModal, setShowModal] = useState(false)
  const [showConfirmModal, setShowConfrimModal] = useState(false)
  console.log(list);
  useEffect(() => {
    (async () =>
      await getUsersList()
    )()
  }, [])


  useEffect(() => {
    (async () =>
      await getUsersList()
    )()
  }, [loading]);

  const borrar = async (id) => {
    await deleteUser(id)
  }
  const restore = async (id) => {
    await restoreUser(id)
  }

  return (
    <>
      <div className='grid grid-cols-1 md:flex md:flex-row justify-between items-center gap-2'>
        <div className='flex flex-col'>
          <h2 className='text-3xl font-bold'>Usuarios</h2>
          <h3 className='hidden md:block text-md font-thin mt-1'>Usuarios Activos:  {total}</h3>
        </div>
        <Button colorScheme='green' onClick={() => setShowModal(!showModal)}>Nuevo Usuario</Button>
      </div>

      <UserTable items={list} user={user} deleteUser={borrar} restoreUser={restore}/>
      <ModalUser show={showModal} setShow={setShowModal}  />
    </>
  )
}

export default Users