import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import
{
  Box, Text, IconButton, Button
} from '@chakra-ui/react'
import { HiArrowNarrowLeft, HiQrcode } from "react-icons/hi";
import AuthContext from '../../contexts/Auth/authContext';
import Summary from '../../components/Orders/Order/Summary';
import Total from '../../components/Orders/Order/Total';
import OrderInfo from '../../components/Orders/Order/OrderInfo';
import ItemMenuRow from './ItemMenuRow';
import { get_categories, update_category } from '../../utils/apis/categories';
import { get_products_by_cat } from '../../utils/apis/products';
import { getProductCartApi, clearProductCartApi, addProductCartApi, get_order, post_order, update_order, cancel_order } from '../../utils/apis/orders';
import { get_tables } from '../../utils/apis/tables'
import { CART } from '../../utils/constants';
import { getTotal } from '../../utils/functions/getTotal.js'
import { AlertaPermisos } from '../../components/Alerta/AlertaPermisos';
import AlertContext from '../../contexts/Alert/alertContext';
import QrOrden from '../../components/Modal/QrOrden';



const Order = () =>
{
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const alertContext = useContext(AlertContext);
  const { alerta } = alertContext;
  const navigate = useNavigate();
  let { id } = useParams()
  const [ orderId, setOrderId ] = useState(null);
  const [ authorization, setAuthorization ] = useState(false);
  const [ showQr, setShowQr ] = useState(false);
  const [ categories, setCategories ] = useState();
  const [ tabIndex, setTabIndex ] = useState(0)
  const [ totalPayment, setTotalPayment ] = useState(0.00);
  const [ category, setCategory ] = useState();
  const [ reloadCart, setReloadCart ] = useState(false);
  const [ products, setProducts ] = useState();//listado de products disponibles
  const [ loadingProducts, setLoadingProducts ] = useState(true)
  const [ orderProducts, setOrderProducts ] = useState([]);
  const [ order, setOrder ] = useState([]);
  const [ tables, setTables ] = useState();
  const [ orderInfo, setOrderInfo ] = useState({
    table: '',
    persons: '',
    name: '',
    phone: '',
    pickUpDate: '',
    pickUpTime: '',
    address: ''
  });

  const getTables = async () =>
  {
    const response = await get_tables();
    setTables(response.results)
  }
  const loadCart = async () =>
  {
    setLoadingProducts(true);
    const response = await getProductCartApi()
    setOrder(response);
    setReloadCart(false);
  }

  const chanegOrderInfo = (e) =>
  {
    setOrderInfo(prevState => ({
      ...prevState,
      [ e.target.name ]: e.target.value,
    }));
  }


  const handleTabsChange = (index) =>
  {
    setTabIndex(index)
  }

  const getOrder = async (id) =>
  {
    setLoadingProducts(true);
    const res = await get_order(id);
    if (res.results.orderType === 'delivery') {
      setTabIndex(2);
    }
    if (res.results.orderType === 'local') {
      setTabIndex(0);
    }
    if (res.results.orderType === 'pickup') {
      setTabIndex(1);
    }
    setOrderInfo(res.results.orderInfo);
    const items = res.results.orderProducts;

    let tempCart = []
    for (let i = 0; i < items.length; i++) {
      tempCart.push({
        _id: items[ i ]._id,
        quantity: items[ i ].quantity,
      })
    }
    await localStorage.setItem(CART, JSON.stringify(tempCart));
  }
  useEffect(() =>
  {
    (async () =>
    {
      setLoadingProducts(true);
      await getAllCategories();
      loadCart()
      getTables();
      setTotalPayment(getTotal(order))
    })()
  }, []);

  useEffect(() =>
  {
    (async () =>
    {
      if (id) {
        setLoadingProducts(true);
        setOrderId(id)
        await getOrder(id)
        loadCart()
        setAuthorization(true)
      }
    })()
  }, [ id ]);

  useEffect(() =>
  {
    (async () =>
    {
      setLoadingProducts(true);
      loadCart()
      getTables();
      setTotalPayment(getTotal(order))
    })()
  }, [ reloadCart ]);

  const getAllCategories = async () =>
  {
    const res = await get_categories();
    setCategories(res.results)
    setCategory(res.results[ 0 ]._id);
    getAllProductsByCategory(res.results[ 0 ]._id);
  }

  const changeCategory = async (id) =>
  {
    setCategory(id);
    getAllProductsByCategory(id);
  }

  const getAllProductsByCategory = async (cat) =>
  {
    const res = await get_products_by_cat(cat);
    setProducts(res.results);
  }


  // const clear = () => {
  //   setReloadCart(true)
  //   clearProductCartApi()
  //   loadCart()
  // }

  const generateOrder = async () =>
  {

    let orderType = null;
    if (tabIndex === 0) {
      orderType = 'local'
    }
    if (tabIndex === 1) {
      orderType = 'pickup'
    }

    if (tabIndex === 2) {
      orderType = 'delivery'
    }
    let data = {
      orderInfo,
      orderType,
      orderProducts: order,
      total: totalPayment
    }

    return data
  }

  const goBack = async () =>
  {
    clearProductCartApi()
    loadCart()
    navigate(-1)
  }

  const putNewOrder = async () =>
  {
    const data = await generateOrder();
    const res = await post_order(data);
    if (res.msg === 'done') {
      goBack()
    }
  }

  const updateOrder = async () =>
  {
    const data = await generateOrder()
    const res = await update_order(orderId, data)
    if (res.msg === 'done') {
      goBack()
    }
  }

  const cancel = () =>
  {
    const data = {
      orderProducts
    }
    cancel_order(orderId, data);
    goBack();
  }


  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col md:flex-row items-start sm:items-center justify-start sm:justify-between w-full'>
        <div className='flex flex-col sm:flex-row items-start md:items-center justify-start sm:justify-between  md:justify-start gap-2 mb-4 sm:md-0 w-full'>
          <Box className='flex flex-row gap-1 items-center justify-start'>
            <IconButton className='w-10' aria-label='Regresar' icon={ <HiArrowNarrowLeft /> } onClick={ () => goBack() } />
            <Text className='text-xl md:text-3xl' as='b'>{ orderId ? `Editar pedido` : 'Crear nuevo pedido' }</Text>
          </Box>
          <Button className='w-full sm:w-auto' onClick={ () => setShowQr(!showQr) }>
            <Text>Mostar QR</Text>
          </Button>
        </div>
        <div className='flex flex-row gap-2 items-center w-full md:w-4/12'>

          { user.role === 'ADMIN' || user.role === 'SUPER_ADMIN' || user.role === 'MANAGER' || user.role === 'CASHIER' ?
            <>
              { orderId && <Button className='w-full' colorScheme='red' disabled={ order.length === 0 } onClick={ () => cancel() }>Cancelar orden</Button> }
            </>
            :
            null
          }

          { !orderId && <Button className='w-full' colorScheme='green' disabled={ order.length === 0 } onClick={ () => putNewOrder() }>Enviar</Button> }
          { orderId &&  <Button className='w-full' colorScheme='green' disabled={ order.length === 0 } onClick={ () => updateOrder() }>Actualizar</Button> }
        </div>
      </div>
      <div>
        <OrderInfo orderInfo={ orderInfo } chanegOrderInfo={ chanegOrderInfo } tables={ tables } tabIndex={ tabIndex } handleTabsChange={ handleTabsChange } />
      </div>
      <div className='flex flex-col-reverse  md:flex-col lg:flex-row gap-4'>

        <div className='w-12/12 lg:w-8/12 mb-2 mt-2 lg:mt-0 lg:mb-0 '>
          <Box w='100%' p={ 4 } borderWidth='1px' borderRadius='lg' className='flex flex-col'>
            <Box className='flex flex-row  flex-wrap gap-2 '>
              { categories ? categories.map(cat => (

                <Button size='md' className='truncate text-left my-1 mx-1' key={ cat._id } onClick={ () => { changeCategory(cat._id) } } colorScheme={ category === cat._id ? 'green' : 'gray' } >
                  <Text noOfLines={ 1 } className='text-sm'> { cat.name }</Text>
                </Button>
              )) : null }

            </Box>
            <Box w="100%" className='flex flex-col justify-between items-start overflow-y-auto'>
              { products && products !== null ? products.map(product => (
                <ItemMenuRow key={ product._id } product={ product } addProductCartApi={ addProductCartApi } reloadCart={ setReloadCart } />
              )) : null }
            </Box>

          </Box>


        </div>
        <div className='w-12/12 lg:w-5/12 flex flex-col gap-4'>
          <div className='hidden md:block'>
            <Total total={ totalPayment } />
          </div>
          <Summary
            authorization={ authorization }
            order={ order }
            reloadCart={ setReloadCart }
            orderProducts={ orderProducts }
            setOrderProducts={ setOrderProducts }
            loadingProducts={ loadingProducts }
            setLoadingProducts={ setLoadingProducts }
            setTotalPayment={ setTotalPayment }
            getTotal={ getTotal }
          />
          <div className='block md:hidden'>
            <Total total={ totalPayment } />
          </div>


        </div>
      </div>
      { alerta && <AlertaPermisos alerta={ alerta } /> }
      { showQr && <QrOrden show={ showQr } setShow={ setShowQr } orderId={ orderId } /> }
    </div>
  )
}

export default Order