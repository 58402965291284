import React from 'react'
import { useColorMode } from '@chakra-ui/color-mode'
import {Text} from '@chakra-ui/react'
import IconError from '../../assets/404.png'
const NotFound = () => {
  const { colorMode } = useColorMode();
  return (
    <div className='flex flex-col w-full gap-1 overflow-x-auto h-full'>
      <div className='mx-2 mt-3'>
        <div className="flex flex-col items-center justify-center">
        <Text  className={`${colorMode === 'light' ? 'text-gray-800' : 'text-white'} text-lg md:text-2xl lg:text-4xl font-semibold font-sans`}>Pagina no encontrado</Text>
          <img className='w-3/12 h-auto' src={IconError} alt="Error" title='Error'/>
       
        </div>
      </div>
    </div>
  )
}

export default NotFound