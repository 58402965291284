import React from 'react'
import { IconButton, Text } from '@chakra-ui/react'
import NoImage from '../../assets/images.png'
import { HiPlus } from 'react-icons/hi'

const ItemMenuRow = ({ product, addProductCartApi, reloadCart }) => {
  return (
    <div className='flex flex-col md:flex-row justify-between items-center gap-1 my-2 w-full border-b pb-2 md:border-b-0'>
      {/* <div className='w-0/12 sm:w-2/12 hidden sm:block'>
        <img src={product?.image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = NoImage;
          }} alt={product?.name} className="w-16 h-16 object-contain" />
      </div> */}
      <div className='w-full md:w-7/12 flex flex-col md:flex-col md:items-start gap-1'>
        <Text noOfLines={1}>{product.name}</Text>
        {product.inventoryStatus && <Text className={`text-xs ${product.qty === 0 && 'text-red-400'} ${product.qty >= 1   && product.qty === 5  && 'text-yellow-400'} ${product.qty >=6 && 'text-green-400'}`}>{product.qty <= 0 ? 'Agotado' : 'Disponible ' + product.qty}</Text>}
      </div>
      <div className='w-full md:w-3/12'>
        <div className='flex flex-row gap-1 items-center justify-between  md:justify-end'>

          <Text className='mx-2'>$ {product.price.toFixed(2)}</Text>
          {product.qty > 0  && product.inventoryStatus ?
            <IconButton aria-label='Agregar' icon={<HiPlus />} onClick={() => { addProductCartApi(product._id, 1); reloadCart(true) }} />
            : null}
            {!product.inventoryStatus ?
            <IconButton aria-label='Agregar' icon={<HiPlus />} onClick={() => { addProductCartApi(product._id, 1); reloadCart(true) }} />
            : null}
        </div>
      </div>
    </div>
  )
}

export default ItemMenuRow