import React from 'react'
import { Box, Link } from '@chakra-ui/react'

const Reports = () => {
  return (
    <>
      <div className='flex flex-row justify-between items-center'>
        <div className='flex flex-col'>
          <h2 className='text-lg md:text-3xl font-semibold'>Reportes</h2>
        </div>

      </div>
      <Box className='flex flex-col md:flex-col justify-start items-start md:items-start gap-1' p={4} mt={2} w='100%' borderWidth='1px' borderRadius='lg'>
        <Link href='#/reports/inventory'>
          Reporte de ventas
        </Link>
        <Link href='#/reports/inventory'>
          Inventorío
        </Link>
      </Box>
    </>
  )
}

export default Reports;
