import React from 'react'
import
  {
    Box, Text,
  } from '@chakra-ui/react';
import Loading from '../../Loading/Loading';

const Total = ({ total }) =>
{
  return (
    <Box w='100%' p={ 4 } borderWidth='1px' borderRadius='lg' className="grid grid-cols-2 place-items-end" >
      <Text mb='4px' mt='4px' fontSize='lg' className='font-bold uppercase place-self-start'>Total</Text>
      { total ? <Text mb='4px' mt='4px' fontSize='lg' className='font-bold uppercase'>$ { (total).toFixed(2) }</Text> : <Loading size="md" /> }
    </Box>
  )
}

export default Total;
