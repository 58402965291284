import React, { useState, useEffect } from 'react'
import { get_inventory, send_inventory_report } from '../../utils/apis/reports'
import { Input, Button, Text, Box, Divider } from '@chakra-ui/react';
import dayjs from 'dayjs';
const Inventory = () => {
  const [items, setItems] = useState();
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  useEffect(() => {
    (async () => {
      setDateStart(dayjs().format('YYYY-MM-DD'))
      setDateEnd(dayjs().format('YYYY-MM-DD'))
      await getInventoryInfo(dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'))
    })()
  }, [])

  const getInventoryInfo = async (start, end) => {
    const date1 = dayjs(start).set('hour', 6).set('minute', 0).set('second', 0).toISOString();
    const date2 = dayjs(end).set('hour', 6).set('minute', 0).set('second', 0).toISOString()
    const res = await get_inventory({ dateStart: date1, dateEnd: date2 });
    setItems(res.results.inventory)
  }

  const sendInventoryInfo = async (start, end) => {
    const date1 = dayjs(start).set('hour', 6).set('minute', 0).set('second', 0).toISOString();
    const date2 = dayjs(end).set('hour', 6).set('minute', 0).set('second', 0).toISOString()
    await send_inventory_report({ dateStart: date1, dateEnd: date2 });
  }



  return (
    <>
      <div className='flex flex-col lg:flex-row justify-between items-stretch lg:items-center gap-2'>
        <h2 className='text-2xl md:text-3xl font-semibold'>Reporte de Inventario</h2>


        <Box className='flex flex-col lg:flex-row gap-2 '>
          <Box className='flex flex-row gap-1 items-center'>
            <Text>De: </Text>
            <Input type='date' value={dateStart} onChange={(e) => setDateStart(e.target.value)} />
          </Box>
          <Box className='flex flex-row gap-1 items-center'>
            <Text>a: </Text>
            <Input type='date' value={dateEnd} onChange={(e) => setDateEnd(e.target.value)} />
          </Box>
          <Button variant='solid' colorScheme='green' onClick={() => getInventoryInfo(dateStart, dateEnd)}>Buscar</Button>
          <Button variant='outline' colorScheme='blue' onClick={() => sendInventoryInfo(dateStart, dateEnd)}>Enviar Reporte</Button>
        </Box>
      </div>
      <Box>
        <Box className='grid grid-cols-2 gap-1 justify-between items-center py-4' w='100%' mt={2}>
          <Box>
            <Text className='font-bold'>Nombre</Text>
          </Box>
          <Box>
            <Text className='font-bold text-right'>Cantidad</Text>
          </Box>
        </Box>
        <Divider />
        {
          items ? items.map(product => (
            <Box key={product._id} className='grid grid-cols-2 px-4 gap-1 border-b border-gray-300 last:border-0' w='100%' py={4}>
              <Text noOfLines={1}>{product.name}</Text>
              <Text className='font-bold text-right'>{(product.qty * -1)} </Text>

            </Box>
          )) :
            null
        }
      </Box>
    </>
  )
}

export default Inventory