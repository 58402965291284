import React from 'react'
import { Spinner } from '@chakra-ui/react'

const Loading = ({size = 'xl'}) => {
  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='gray.500'
        size={size}
      />
    </div>
  )
}

export default Loading