import React from 'react'
import {
  Button,
  IconButton,
  Tag,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tooltip
} from '@chakra-ui/react'

import { HiTrash } from 'react-icons/hi'
import {FaTrashRestore} from 'react-icons/fa'
import Loading from '../Loading/Loading'

const UserTable = ({ items, user, deleteUser, restoreUser }) => {


  return (
    <div className='my-2'>
      <TableContainer>
        <Table variant='striped' colorScheme='gray'>
          <Thead>
            <Tr>
              <Th>Nombre</Th>
              <Th>Correo</Th>
              <Th>Role</Th>
              <Th>Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {items ? items.map((item, i) => (
              <Tr key={item.uid}>
                <Td>
                  {item.name}
                </Td>
                <Td>
                  {item._id}
                  {item.email}
                </Td>
                <Td>
                  {item.role}
                </Td>
                <Td>
                  <Tag colorScheme={item.status ? 'green' : 'red'}>{item.status ? 'Activo' : 'Suspendido'}</Tag>
                </Td>
                <Td>
                  {item.status && <Tooltip label='Dar de baja'>
                    <IconButton icon={<HiTrash />} variant='ghost' colorScheme='red' disabled={user.uid === item.uid ? true : false} onClick={() => deleteUser(item.uid)} />
                  </Tooltip>}
                  {!item.status && <Tooltip label='Restaurar'>
                    <IconButton icon={<FaTrashRestore />} variant='ghost' colorScheme='blue' disabled={user.uid === item.uid ? true : false} onClick={() => restoreUser(item.uid)} />
                  </Tooltip>}
                </Td>
              </Tr>
            )) : null}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default UserTable