import React from 'react'
import { Text, Box, Divider, Input, InputGroup, InputRightAddon, InputLeftAddon, Button } from '@chakra-ui/react'
const AmountSummary = ({ subTotal, ivaAmount, total, desc, setDesc, addTransaction }) => {

  return (
    <>
      <Box>

        <Box className='flex flex-row items-center justify-end' p={2}>
          <Text className='text-sm font-bold mx-2'>Subtotal</Text>
          <Text className='text-lg'>$ {subTotal} MXN</Text>
        </Box>

      </Box>

      <Box className='flex flex-row items-center justify-end' p={2}>
        <Text className='text-sm font-bold mx-2'>Incluye IVA</Text>
        <Text className='text-lg'>$ {ivaAmount} MXN</Text>
      </Box>
      <Divider my={2} />
      <Box className='flex flex-row items-center justify-end' p={2}>
        <Text className='text-sm font-bold mx-2'>Descuento</Text>
        <Box className='w-full sm:w-6/12 md:w-3/12'>
          <InputGroup >
            {/* <InputLeftAddon children="-" /> */}
            <Input type='number' min={0} value={desc} onChange={(e) => setDesc(e.target.value)} />
            <InputRightAddon children='%' />
          </InputGroup>
        </Box>
      </Box>
      <Divider my={2} />

      <Box className='flex flex-row items-center justify-end' p={2}>
        <Text className='text-sm font-bold mx-2'>Total</Text>
        <Text className='text-lg'>$ {total} MXN </Text>
      </Box>

      <Box className='flex flex-row items-center justify-end gap-2 mb-4' p={2} mb={4}>
      <Button className='w-full' variant='ghost' colorScheme='red'>Cancelar</Button>
       <Button className='w-full' variant='solid' colorScheme='green' onClick={addTransaction}>Pagar</Button>
      </Box>

    </>
  )

}

export default AmountSummary