import React, { useState, useContext } from 'react'
import md5 from 'md5';
import
  {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Stack,
    InputGroup,
    InputRightAddon,
    Input
  } from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { update_password } from '../../utils/apis/users'
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi'
import AuthContext from '../../contexts/Auth/authContext';

const ChangePassword = ({ id, show, setShow }) =>
{
  const authContext = useContext(AuthContext);
  const { user } = authContext
  const [ showPassword, setShowPassword ] = useState(false)

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) =>
    {
      const password = {
        password: md5(data.password),
        repeatPassword: md5(data.repeatPassword)
      }
      await update_password(user.uid, password)
      formik.resetForm()
      setShow(!show)

    }
  })


  return (
    <Modal isOpen={ show } onClose={ setShow }>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cambiar contraseña</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={ 4 }>
            <InputGroup>
              <Input
                value={ formik.values.password }
                onChange={ (e) => formik.setFieldValue('password', e.target.value) }
                type={ showPassword ? 'text' : 'password' } />
              <InputRightAddon children={ showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye /> } onClick={ () => setShowPassword(!showPassword) } />
            </InputGroup>
            <InputGroup>
              <Input value={ formik.values.repeatPassword }
                onChange={ (e) => formik.setFieldValue('repeatPassword', e.target.value) } type={ showPassword ? 'text' : 'password' } />
              <InputRightAddon children={ showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye /> } onClick={ () => setShowPassword(!showPassword) } />
            </InputGroup>



          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant='ghost' colorScheme='gray' mr={ 3 } onClick={ () => setShow(!show) }>
            Cancelar
          </Button>
          <Button variant='solid' colorScheme='green' onClick={ () => formik.handleSubmit() }>Cambiar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}


export default ChangePassword;

function initialValues ()
{
  return {
    password: '',
    repeatPassword: '',

  }
}

function validationSchema ()
{
  return {
    password: Yup.string()
      .min(1, 'La contraseña debe de ser Mayor o igual a 10 caracteres')
      .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
      .required(true),
    repeatPassword: Yup.string()
      .min(1, 'La contraseña debe de ser Mayor o igual a 10 caracteres')
      .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
      .required(true).oneOf([ Yup.ref('password'), null ], 'No coinciden con la contraseña')
  }
}