import React, { useState, useEffect } from 'react'
import { get_sales, send_sales_report } from '../../utils/apis/reports'
import { Input, Button, Text, Box, Divider, Avatar } from '@chakra-ui/react';
import { MdOutlineDeliveryDining, MdOutlineShoppingBag, MdOutlineChairAlt } from 'react-icons/md'
import { getTotal, getIva, getDiscount, getSubTotal } from '../../utils/functions/getDailyTotal';
import { FaTags } from 'react-icons/fa';
import dayjs from 'dayjs';
import DailyRow from '../../components/Reports/Daily/DailyRow';
import BoxSummary  from '../../components/Dashboard/BoxSummary'
const Daily = () =>
{
  const [ items, setItems ] = useState();
  const [ dateStart, setDateStart ] = useState(null);
  const [ dateEnd, setDateEnd ] = useState(null);
  const [ summary, setSummary ] = useState({
    subtotal: 0,
    discount: 0,
    tax: 0,
    total: 0
  })

  useEffect(() =>
  {
    (async () =>
    {
      setDateStart(dayjs().format('YYYY-MM-DD'))
      setDateEnd(dayjs().format('YYYY-MM-DD'))

      await getSalesReport(dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'))
    })()
  }, [])

  const getSalesReport = async (start, end) =>
  {
    const date1 = dayjs(start).set('hour', 6).set('minute', 0).set('second', 0).toISOString();
    const date2 = dayjs(end).set('hour', 6).set('minute', 0).set('second', 0).toISOString()
    const res = await get_sales({ dateStart: date1, dateEnd: date2 });
    const total = getTotal(res.results.orders)
    const tax = getIva(res.results.orders)
    const discount = getDiscount(res.results.orders)
    const subtotal = getSubTotal(res.results.orders)
    setItems(res.results.orders);
    setSummary({
      subtotal,
      discount,
      tax,
      total
    })
  }

  const sendSalesInfo = async (start, end) =>
  {
    const date1 = dayjs(start).set('hour', 6).set('minute', 0).set('second', 0).toISOString();
    const date2 = dayjs(end).set('hour', 6).set('minute', 0).set('second', 0).toISOString()
    await send_sales_report({ dateStart: date1, dateEnd: date2 });

  }

  return (
    <>
      <div className='flex flex-col lg:flex-row justify-between items-stretch lg:items-center gap-2'>
        <h2 className='text-2xl md:text-3xl font-semibold'>Reporte de Ventas</h2>


        <Box className='flex flex-col lg:flex-row gap-2 print:hidden '>
          <Box className='flex flex-row gap-1 items-center'>
            <Text>De: </Text>
            <Input type='date' value={ dateStart } onChange={ (e) => setDateStart(e.target.value) } />
          </Box>
          <Box className='flex flex-row gap-1 items-center'>
            <Text>a: </Text>
            <Input type='date' value={ dateEnd } onChange={ (e) => setDateEnd(e.target.value) } />
          </Box>
          <Button variant='outline' colorScheme='green' onClick={ () => getSalesReport(dateStart, dateEnd) }>Buscar</Button>
          <Button variant='outline' colorScheme='blue' onClick={ () => sendSalesInfo(dateStart, dateEnd) }>Enviar Reporte</Button>
        </Box>
      </div>
      <Box className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2'>
      <BoxSummary title={'Subtotal'} value={summary.total ? `$ ${summary.total.toFixed(2)}` : '$ 0.00'} color='white' />
      <BoxSummary title={'Descuento'} value={summary.discount ? `$ ${summary.discount.toFixed(2)}` : '$ 0.00'} color='white' />
      <BoxSummary title={'IVA'} value={summary.tax ? `$ ${summary.tax.toFixed(2)}` : '$ 0.00'} color='white' />
      <BoxSummary title={'Total'} value={summary.subtotal ? `$ ${summary.subtotal.toFixed(2)}` : '$ 0.00'} color='white' />
      </Box>
      <Box className='print:block"'>
        {
          items ? items.map(order => (
            <DailyRow key={ order._id } order={ order } />
          )) :
            null
        }
      </Box>
    </>
  )
}

export default Daily