import React, { useContext, useEffect } from 'react'
import AuthContext from '../../contexts/Auth/authContext';
import { useColorMode } from '@chakra-ui/color-mode'
import { HiArrowSmLeft, HiOutlineHome, HiOutlineDocumentReport, HiOutlineIdentification, HiOutlineReceiptTax } from "react-icons/hi";
import { BiFoodMenu, BiCategoryAlt } from "react-icons/bi";
import { MdOutlineAnalytics } from "react-icons/md";
import { GiTable } from "react-icons/gi";
import MenuItem from './MenuItem';
import { Divider, Text } from '@chakra-ui/react';
const Sidebar = ({ showSidebar, show, showText }) => {
  const { colorMode } = useColorMode();
  const authContext = useContext(AuthContext)
  const { user } = authContext;


  return (
    <>
      <aside
        className={`z-0 print:hidden flex flex-col justify-between pt-4 ${showText ? 'w-2/12 lg:2/12' : 'w-16 lg:w-16'} text-gray-800  h-full md:h-auto  z-0  ease-in-out duration-300 ${colorMode === 'light' ? 'border-gray-100' : 'border-gray-700'}
            }`}
      >

        <nav className='flex flex-col px-1 mr-4 md:mr-0 w-full'>
          {user.role === 'ADMIN' || user.role === 'SUPER_ADMIN' || user.role === 'MANAGER' ?
            <MenuItem showText={showText} title="Dashboard" link="/admin" icon={<HiOutlineHome className='text-md lg:text-lg' />} />
            : null
          }
          <MenuItem showText={showText} title="Pedidos" link="/orders" icon={<HiOutlineReceiptTax className='text-md lg:text-lg' />} />
          {/* <MenuItem showText={showText} title="Caja" link="/pos" icon={<FaCashRegister className='text-md lg:text-lg' />} /> */}
          {user.role === 'ADMIN' || user.role === 'SUPER_ADMIN' || user.role === 'MANAGER' ?
            <MenuItem showText={showText} title="Productos" link="/admin/products" icon={<BiFoodMenu className='text-md lg:text-lg' />} />
            : null
          }
          {user.role === 'ADMIN' || user.role === 'SUPER_ADMIN' || user.role === 'MANAGER' ? <MenuItem showText={showText} title="Categorias" link="/admin/categories" icon={<BiCategoryAlt className='text-md lg:text-lg' />} /> : null}
          {/* <MenuItem showText={showText} title="Cocina" link="/kitchen" icon={<MdOutdoorGrill className='text-md lg:text-lg' />} />
          <MenuItem showText={showText} title="Bar" link="bar" icon={<BiDrink className='text-md lg:text-lg' />} /> */}
          {/* <MenuItem showText={showText} title="Clientes" link="customers" icon={<HiOutlineUser className='text-md lg:text-lg' />} /> */}
          <MenuItem showText={showText} title="Mesas" link="/settings/tables" icon={<GiTable className='text-md lg:text-lg' />} />
          {user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'  ? <MenuItem showText={showText} title="Usuarios" link="/users" icon={<HiOutlineIdentification className='text-md lg:text-lg' />} /> : null}
          {user.role === 'ADMIN' || user.role === 'SUPER_ADMIN' || user.role === 'MANAGER' ? <>
            <Divider className='my-2' />
            {showText && <Text fontSize='xs' color='gray' className='py-2 px-4 hidden md:block'>Reportes</Text>}
            <MenuItem showText={showText} title="Ventas" link="/reports/sales" icon={<HiOutlineDocumentReport className='text-md lg:text-lg' />} />
            <MenuItem showText={showText} title="Inventario" link="/reports/inventory" icon={<MdOutlineAnalytics className='text-md lg:text-lg' />} />
          </>
            : null
          }
        </nav>
      </aside>

    </>

  )
}

export default Sidebar