import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Input, Text, Stack, Textarea, Select, IconButton, Button } from '@chakra-ui/react'
import { HiArrowNarrowLeft } from "react-icons/hi";
import OrdersList from '../../components/Orders/OrdersList';
import { SocketContext } from '../../contexts/Socket/socket';
import { get_all_orders } from '../../utils/apis/orders'

const Orders = () => {
  const socket = useContext(SocketContext)
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    getAllOrders()
  }, []);
  useEffect(()=>{
    socket.on('listOrders', (payload)=>{
        console.log(payload)
        setOrders(payload.orders);
        setTotal(payload.total);
    })
}, [socket])


  const getAllOrders = async () => {
    const res = await get_all_orders();
    setOrders(res.results.orders);
    setTotal(res.results.total)
  }
  return (
    <>
      <div className='grid grid-cols-1 md:flex md:flex-row justify-between items-center gap-2'>
        <div className='flex flex-col'>
          <h2 className='text-3xl font-semibold'>Pedidos</h2>
          <h3 className='hidden md:block text-md font-thin mt-2'>Total pedidos abiertos {total}</h3>
        </div>
        <Button colorScheme='green' onClick={() => navigate('/orders/new')}>Nuevo pedido</Button>
      </div>

        <OrdersList orders={orders} />

    </>
  )
}

export default Orders