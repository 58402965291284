import React, { useState } from 'react'
import md5 from 'md5';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Stack,
  InputGroup,
  InputRightAddon,
  Input,
  Select
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { post_user, update_user } from '../../utils/apis/users'
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi'

const ChangePassword = ({ id, show, setShow }) => {
  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      console.log(data)
      const user = {
        name: data.name,
        email: data.email,
        role: data.role,
        password: md5(data.password),
        repeatPassword: md5(data.repeatPassword)
      }
      await post_user(user)
      formik.resetForm()
      setShow(!show)

    }
  })
  return (
    <Modal isOpen={show} onClose={setShow}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alta de Usuario</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>

            <Text className='text-sm mb-0 pb-0'>Nombre</Text>
            <Input placeholder='Nombre' value={formik.values.name} onChange={(e) => formik.setFieldValue('name', e.target.value)} isInvalid={formik.errors.name}
              errorBorderColor='red.300' />
            <Text className='text-sm mb-0 pb-0'>Correo</Text>
            <Input placeholder='Email' value={formik.values.email} onChange={(e) => formik.setFieldValue('email', e.target.value)} isInvalid={formik.errors.email}
              errorBorderColor='red.300' />
            <Text className='text-sm mb-0 pb-0'>Permisos</Text>
            <Select placeholder='' value={formik.values.role} onChange={(e) => formik.setFieldValue('role', e.target.value)} isInvalid={formik.errors.role}
              errorBorderColor='red.300'>
              <option value=''></option>
              <option value='ADMIN'>Admininstrador</option>
              <option value='MANAGER'>Gerente</option>
              <option value="WAITER">Mesero</option>
              <option value="CASHIER">Cajero</option>
            </Select>
            <Text className='text-sm mb-0 pb-0'>Contraseña</Text>
            <InputGroup>
   
              <Input
                value={formik.values.password}
                placeholder='Contraseña'
                onChange={(e) => formik.setFieldValue('password', e.target.value)}
                type={showPassword ? 'text' : 'password'} isInvalid={formik.errors.password}
                errorBorderColor='red.300' />
              <InputRightAddon children={showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />} onClick={() => setShowPassword(!showPassword)} />
            </InputGroup>
            <Text className='text-sm mb-0 pb-0'>Repetir contraseña</Text>
            <InputGroup>

              <Input value={formik.values.repeatPassword}
                placeholder='Repetir contaseña'
                isInvalid={formik.errors.repeatPassword}
                errorBorderColor='red.300'
                onChange={(e) => formik.setFieldValue('repeatPassword', e.target.value)} type={showPassword ? 'text' : 'password'} />
              <InputRightAddon children={showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />} onClick={() => setShowPassword(!showPassword)} />
            </InputGroup>



          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant='ghost' colorScheme='gray' mr={3} onClick={() => setShow(!show)}>
            Cancelar
          </Button>
          <Button variant='solid' colorScheme='green' onClick={() => formik.handleSubmit()}>Cambiar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}


export default ChangePassword;

function initialValues () {
  return {
    name: '',
    email: '',
    role: '',
    password: '',
    repeatPassword: '',

  }
}

function validationSchema () {
  return {
    name: Yup.string().required(true, 'El nombre es obligatorio'),
    email: Yup.string().required(true, 'El correo es obligatorio'),
    role: Yup.string().required(true, 'El rol es obligatorio'),
    password: Yup.string()
      .min(1, 'La contraseña debe de ser Mayor o igual a 10 caracteres')
      .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
      .required(true),
    repeatPassword: Yup.string()
      .min(1, 'La contraseña debe de ser Mayor o igual a 10 caracteres')
      .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
      .required(true).oneOf([Yup.ref('password'), null], 'No coinciden con la contraseña')
  }
}