import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import {
  Button,
  Tag,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'
import Loading from '../../../components/Loading/Loading';
import { get_products, update_product } from '../../../utils/apis/products'
import ProductRow from '../../../components/Products/ProductRow';

const Products = () => {
  const navigate = useNavigate()
  const [items, setItems] = useState();


  useEffect(() => {
    (async () => {
      await getProducts()
    })()
  }, [])
  
  const getProducts = async ()=>{
    const res = await get_products();
    console.log(res)
    setItems(res.results)
  }
  const editProduct = (id) => {
    navigate(`/admin/products/${id}`);
  }

  const updateProduct = async (id, newStatus) => {
    await update_product(id, { status: newStatus })
    getProducts()
  }



  return (
    <>
      <div className='grid grid-cols-1 md:flex md:flex-row justify-between items-start md:items-center gap-1'>
        <h2 className='text-3xl font-semibold'>Productos</h2>
        <Button colorScheme='green' onClick={() => navigate('/admin/products/new')}>Crear producto</Button>
      </div>
      <div className='my-2'>
        <TableContainer>
          <Table variant='striped' colorScheme='gray'>
            <Thead>
              <Tr>
                <Th>Nombre</Th>
                <Th>Precio</Th>
                <Th>Cnt</Th>
                <Th>Estado</Th>
                <Th>Categoría</Th>
  
              </Tr>
            </Thead>
            <Tbody>
              {items ? items.map((item, i) => (
                <ProductRow key={item._id} item={item} editProduct={editProduct} updateProduct={updateProduct} />
              )) : null}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}

export default Products