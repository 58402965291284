import React, {useState, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, Input, Text, Stack, Textarea, Select, IconButton, Button, FormControl } from '@chakra-ui/react'
import { HiArrowNarrowLeft } from "react-icons/hi";
import { post_category, get_category,update_category } from '../../../utils/apis/categories';

const Category = () => {
  const navigate = useNavigate();
  const [_id, setId] = useState(null)
  let {id} = useParams() 
  useEffect(() => {
    (async()=>{
      if(id){
        console.log('id ',id)
        setId(id)
        const res = await get_category(id)
        console.log(res)
        console.log(res.results.status ? 'visible':'hide')
        formik.setFieldValue('name', res.results.name)
        formik.setFieldValue('status', res.results.status ? 'visible':'hide')
      }
    })()
  }, [])
  
  const resteFormikValue = async () => {
    formik.resetForm()
  }
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      const cat = {
        name:data.name,
        describe:data.description,
        image:data.image
      }
      if(data.status === 'visible'){
        cat.status = true;
      }

      if(data.status === 'hide'){
        cat.status = false;
      }

      if(_id){
        console.log(_id)
        console.log(cat)
        await update_category(_id,cat);
      }else{
        await post_category(cat)
      }
     resteFormikValue()
     navigate(-1)
    }
  })
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row flex-wrap md:flex-row items-start md:items-center justify-between'>
        <div className='flex flex-row items-center justify-start gap-2'>
          <IconButton aria-label='Search database' icon={<HiArrowNarrowLeft />} onClick={() => navigate(-1)} />
          <Text fontSize='3xl' as='b' noOfLines={1}>{id ? formik.values.name : 'Crear nueva Categoría'}</Text>
        </div>
        <div className='flex flex-row md:flex-row gap-2 w-full md:w-auto'>
          <Button className=' w-full' colorScheme='gray' onClick={() => navigate(-1)} >Descartar</Button>
          <Button className=' w-full' colorScheme='green' onClick={() => {formik.handleSubmit() }}>Guardar</Button>
        </div>
      </div>
      <FormControl onSubmit={formik.handleSubmit} className='flex flex-col md:flex-row gap-4'>
        <div className='w-12/12 md:w-8/12'>
          <Box w='100%' p={4} borderWidth='1px' borderRadius='lg' >
            <Stack>
              <div>
                <Text mb='8px' fontSize='lg'>Nombre</Text>
                <Input placeholder='Nombre de la categoría' value={formik.values.name} onChange={(e)=> formik.setFieldValue('name',e.target.value )}/>
              </div>
              <div>
                <Text mb='8px' fontSize='lg'>Descripción</Text>
                <Textarea placeholder='' value={formik.values.description} onChange={(e)=> formik.setFieldValue('description',e.target.value )}/>
              </div>
            </Stack>
          </Box>
        </div>
        <div className='w-12/12 md:w-4/12 flex flex-col gap-4'>
          <Box w='100%' p={4} borderWidth='1px' borderRadius='lg' >
            <Text mb='8px' fontSize='lg'>Visibilidad</Text>
            <Select placeholder=' ' value={formik.values.status} onChange={(e)=> formik.setFieldValue('status',e.target.value === 'visible' ? 'visible' : 'hide' )}>
              <option value='visible'>Visible</option>
              <option value='hide'>Ocultar</option>
            </Select>
          </Box>
          <Box w='100%' p={4} borderWidth='1px' borderRadius='lg' >
            <Text mb='8px' fontSize='lg'>Imagen de la categoría</Text>
            <Input size='lg' p={2} type='file' value={formik.values.image} onChange={(e)=> formik.setFieldValue('image',e.target.value )} />
          </Box>
        </div>
      </FormControl>

    </div>
  )
}

export default Category;

function initialValues () {
  return {
    name: '',
    description: '',
    status:'',
    image:''
  }
}

function validationSchema () {
  return {
    name: Yup.string().required(true),
    description: Yup.string(),
    image: Yup.string(),
    status: Yup.string(),
  }
}