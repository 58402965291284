const token = localStorage.getItem('token')

export const get_users = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/v1/api/users`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    console.log(result);
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}



export const post_user = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/v1/api/users`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const update_user = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/v1/api/users/update_user`;
    const params = {
      method: 'PUT',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const update_password = async (id,data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/v1/api/users/${id}`;
    const params = {
      method: 'PATCH',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}



export const delete_user = async (id) => {

  try {
    const url = `${process.env.REACT_APP_API_URL}/v1/api/users/${id}`;
    const params = {
      method: 'DELETE',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const restore_user = async (id) => {

  try {
    const url = `${process.env.REACT_APP_API_URL}/v1/api/users/restore/${id}`;
    const params = {
      method: 'PATCH',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}