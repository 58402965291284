import React, { useState, useEffect, useContext } from 'react';
import md5 from 'md5';
import Logo from '../../assets/mi-xula.png'
import
{
  Button,
  Box,
  Text,
  Stack,
  InputGroup,
  InputRightAddon,
  Input
} from '@chakra-ui/react'
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom';
import { getRecoveryInfo } from '../../utils/apis/auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { recovery_update_password } from '../../utils/apis/auth'
import ToggleButton from '../../components/Header/ToggleButton';

const ResetPassword = () =>
{
  const navigate = useNavigate();
  let { id } = useParams();
  const [ user, setUser ] = useState();
  const [ showPassword, setShowPassword ] = useState(false)
  const [ showMessage, setShowMessage ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) =>
    {
      setIsLoading(!isLoading)
      const password = {
        password: md5(data.password),
        repeatPassword: md5(data.repeatPassword)
      }
      const res = await recovery_update_password(id, password)
      if (res.msg === 'done') {

        formik.resetForm();
        navigate('/login');
      }
      setIsLoading(!isLoading)

    }
  })

  useEffect(() =>
  {
    (async () =>
    {
      if (id) {
        const res = await getRecoveryInfo(id)
        if (res.msg !== 'done') {
          setShowMessage(true)
        }
      }
    })()
  }, [ id ])


  return (
    <>
      <div className='w-full gap-1 h-[100vh] flex flex-col justify-center  overflow-x-auto  relative'>
        <Box className='flex flex-row absolute top-0 right-2'> <ToggleButton /></Box>

        <div className='flex flex-col  items-center justify-center w-full gap-1 self-stretch'>
          <div className='flex flex-col  items-center justify-center gap-1 w-full md:w-8/12 lg:w-6/12'>
            <img src={ Logo } alt="Logo mi xula" className="w-40" />
            { showMessage ? <>
              <Text className='text-center text-lg '>Lo sentimos este enlace ya fue utilizado o caduco</Text>
              <Button
                colorScheme="green"
                variant="solid"

                className="w-full md:w-64 uppercase my-4"
                onClick={ () => navigate('/login') }
              >
                Ir a iniciar sesión
              </Button>
            </>
              :
              <>
                <Box className='flex flex-col items-center mb-2'>

                  <Text className='text-center text-2xl my-3 font-semibold '>Recuperar contraseña</Text>

                  <Text className='text-center text-sm '>Por favor rellena los campos siguientes</Text>
                </Box>
                <Stack spacing={ 4 }>
                  <Box>
                    <span className="block text-sm font-medium">Contraseña</span>
                    <InputGroup>

                      <Input
                        value={ formik.values.password }
                        placeholder="Contraseña"
                        onChange={ (e) => formik.setFieldValue('password', e.target.value) }
                        type={ showPassword ? 'text' : 'password' } />
                      <InputRightAddon children={ showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye /> } onClick={ () => setShowPassword(!showPassword) } />
                    </InputGroup>
                  </Box>
                  <Box>
                    <span className="block text-sm font-medium">Repetir contraseña</span>
                    <InputGroup>
                      <Input value={ formik.values.repeatPassword }
                        placeholder="Repetir contraseña"
                        onChange={ (e) => formik.setFieldValue('repeatPassword', e.target.value) } type={ showPassword ? 'text' : 'password' } />
                      <InputRightAddon children={ showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye /> } onClick={ () => setShowPassword(!showPassword) } />
                    </InputGroup>
                  </Box>

                  <Button
                    colorScheme="green"
                    variant="solid"
                    className="w-full uppercase"
                    isLoading={isLoading}
                    loadingText='Enviando'
                    onClick={ () => formik.handleSubmit() }
                  >
                    Cambiar contraseña
                  </Button>

                </Stack>
              </>
            }
          </div>

        </div>
        {/* <Box className='flex flex-row w-full absolute bottom-0'>
            { alerta !== null ?
                <Box className='w-full'>
                    <Alert status={ alerta.categoria } variant='solid'>
                        <AlertIcon />
                        <Box>
                            <AlertTitle>Ocurrio un error</AlertTitle>
                            { alerta.msg }
                        </Box>
                    </Alert>
                </Box>
                : null }
        </Box> */}

      </div>

    </>
  )
}

export default ResetPassword

function initialValues ()
{
  return {
    password: '',
    repeatPassword: '',

  }
}

function validationSchema ()
{
  return {
    password: Yup.string()
      .min(1, 'La contraseña debe de ser Mayor o igual a 10 caracteres')
      .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
      .required(true),
    repeatPassword: Yup.string()
      .min(1, 'La contraseña debe de ser Mayor o igual a 10 caracteres')
      .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
      .required(true).oneOf([ Yup.ref('password'), null ], 'No coinciden con la contraseña')
  }
}