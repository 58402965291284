export const getTotal = (items)=>{
  let total=0;
  let i = 0;
  for(i; i<items.length; i++){
    total += items[i].total
  }
  return total;
}

export const getIva = (items)=>{
  let total=0;
  let i = 0;
  for(i; i<items.length; i++){
    total += items[i].tax
  }
  return total;
}

export const getDiscount = (items)=>{
  let total=0;
  let i = 0;
  for(i; i<items.length; i++){
    total += items[i].discount
  }
  return total;
}

export const getSubTotal = (items)=>{
  let total=0;
  let i = 0;
  for(i; i<items.length; i++){
    total += items[i].subtotal
  }
  return total;
}