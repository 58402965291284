import React from 'react'
import dayjs from 'dayjs'
import { Text, Box, Divider } from '@chakra-ui/react';
import Row from './OrderSummary/Row';

const OrderSummary = ({ orderInfo, orderDetail }) => {

  return (
    <>
      <Box className='w-12/12' p={2} mt={2} borderWidth='1px' borderRadius='lg' >
        <Text className='text-xl font-bold mb-3'>Datos de la orden</Text>
        <Row colName='Fecha de la orden:' colInfo={dayjs(orderDetail?.date).format('DD/MM/YYYY HH:mm a')} />
        <Divider />
        <Row colName='Mesa:' colInfo={orderInfo.table} />
        <Divider />
        <Row colName='Personas:' colInfo={orderInfo.persons} />
        <Divider />
        <Row colName='Fecha de entrega:' colInfo={orderInfo.pickDate} />
        <Divider />
        <Row colName='Hora:' colInfo={orderInfo.pickUpTime} />
        <Divider />
        <Row colName='Cliente' colInfo={orderInfo.name} />
        <Divider />
        <Row colName='Tel:' colInfo={orderInfo.phone} />
        <Divider />
        <Row colName='Dirección:' colInfo={orderInfo.address} />
      </Box>
    </>
  )
}

export default OrderSummary