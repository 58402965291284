import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import dayjs from 'dayjs'
import {
  Box, Divider, Text,
  IconButton,
  Button,
  Tooltip,
  Avatar
} from '@chakra-ui/react'
import relativeTime from 'dayjs/plugin/relativeTime'
import locale_es from 'dayjs/locale/es-mx'
import { MdOutlineDeliveryDining, MdOutlineShoppingBag, MdOutlineChairAlt, MdOutlineRemoveRedEye } from 'react-icons/md';
import AuthContext from '../../contexts/Auth/authContext'

dayjs.extend(relativeTime)
const OrderView = ({ order }) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const navigate = useNavigate()


  const editOrder = async (id) => {
    navigate(`/orders/${id}`)
  }


  if (order.status === 'open') {
    return (
      <Box

        p={2} mb={2} borderRadius='lg' className='mx-0 md:mx-2 my-2 w-full md:w-64 border-l-4 border-l-green-600 border border-gray-200 grid grid-cols-1'>
        <div className='flex flex-row items-center justify-between'>
          <Box className='flex flex-row items-center justify-between'>
            {order?.orderType === 'local' && <MdOutlineChairAlt className='text-2xl' />}
            {order?.orderType === 'delivery' && <MdOutlineDeliveryDining className='text-2xl' />}
            {order?.orderType === 'pickup' && <MdOutlineShoppingBag className='text-xl' />}
            <Text fontSize='xs' className='mx-2'>{dayjs().locale(locale_es).to(dayjs(order.date))}</Text>
          </Box>
          <Box>
       <Tooltip label={order?.user?.name}  aria-label='Nombre de quien tomo la orden'>
       <Avatar name={order?.user?.name} size='xs' />
       </Tooltip>
          </Box>
          {user.role === 'ADMIN' || user.role === 'SUPER_ADMIN' || user.role === 'MANAGER' || user.role === 'CASHIER' ?
            <Tooltip label="Editar orden" aria-label='A tooltip'>
              <IconButton icon={<MdOutlineRemoveRedEye />} variant="ghost" colorScheme="blue" onClick={() => editOrder(order._id)} />
            </Tooltip>
            :
            null
          }
        </div>
        <div className='grid grid-cols-1 justify-between items-center'>
          <Text noOfLines={1}>{order.orderInfo?.table} {order.orderInfo?.name}</Text>
          <Box>
            <Divider />
            <div className='grid grid-cols-2 my-1'>
              <Text className='font-bold'>Total:</Text>
              <Text noOfLines={1} className="text-right">$ {order?.total.toFixed(2)}</Text>
            </div>
          </Box>
          <Box>
            <Divider />
            {user.role === 'ADMIN' || user.role === 'SUPER_ADMIN' || user.role === 'MANAGER' || user.role === 'CASHIER' ?
              <div className='grid grid-cols-2 mt-2 gap-1'>
                <Button size='xs' variant='ghost' colorScheme='red'>Cancelar</Button>
                <Button size='xs' variant='outline' colorScheme='green' onClick={() => navigate(`/pos/${order._id}`)}>Pagar</Button>
              </div>
              :

              <div className='grid grid-cols-1 mt-2 gap-1'>
                <Button size='xs' variant='outline' colorScheme='green' onClick={() => editOrder(order._id)}>Editar</Button>
              </div>
            }
          </Box>


        </div>
      </Box>
    );
  }

}

export default OrderView