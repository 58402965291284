import React from 'react'
import { Text, Box,Divider } from '@chakra-ui/react'

const OrderResumenRow = ({ product }) => {
  return (
    <>

    <Box className='flex flex-row justify-between items-center' p={2}   mx={2} mt={2}>
       <div className='flex flex-row gap-1 items-baseline'>
       <Text className='text-xs'>{product.quantity} x</Text>
      <Text>{product.name}</Text>
      </div>
      <div className='flex flex-row gap-1 items-baseline'>
        <Text className='text-sm'>$ {(product.price * product.quantity).toFixed(2)}</Text>
      </div>

    </Box>
    <Divider my={2} />
    </>
  )
}

export default OrderResumenRow