import React  from 'react'
import { Box } from '@chakra-ui/react'
import OrderView from './OrderView'

const OrdersList = ({orders}) => {


  return (
    <Box className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-2 gap-4'>
      {orders ? orders.map(order => (
        <OrderView key={order._id} order={order} />
      )) : null}
    </Box>
  )
}

export default OrdersList