import { get_product } from "../apis/products";

export const localOrder = async(products) =>{
  const productTemp=[];
      for await (const product of products){

        const res = await get_product(product._id);

        res.results.quantity = product.quantity
        productTemp.push({
          quantity:product.quantity,
          name: res.results.name,
          price:res.results.price,
          _id:product._id,
        })
      } 

  return productTemp;

}