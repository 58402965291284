import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react'
import QRCode from "react-qr-code";

const QrOrden = ({ show, setShow, orderId}) => {

  return (
    <Modal isOpen={show} onClose={setShow}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Escannea para ver tu pedido</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        { orderId && <Box w='100%' p={ 4 }  className="grid grid-cols-1 place-items-center" >
            <QRCode value={ `https://www.mi-xula.com/#/orders/${ orderId }` } className='rounded-lg' />
          </Box> }
        </ModalBody>

        <ModalFooter>
          <Button className='w-full' variant='solid' colorScheme='green' mr={3} onClick={() => setShow(!show)}>
            Cerrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}


export default QrOrden;
