import React from 'react'
import { Spinner, Box, Text } from '@chakra-ui/react'

const Loading = () => {
  return (
    <Box width='100%' p="4" display='flex' className='flex flex-col w-full items-center' >
        <Spinner  size="xl" color="green.400"/>
        <Text className='my-2'>Cargando...</Text>
    </Box>
  )
}

export default Loading