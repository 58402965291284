import React from 'react'
import { Tr, Td, IconButton, Text, Box } from '@chakra-ui/react'
import { HiPlus, HiMinus, HiTrash } from 'react-icons/hi'

const OrderRow = ({ product, increase, decrease, deleteProduct, user, authorization }) =>
{
  return (
    <Tr>
      <Td>
        <Box className='flex flex-row items-center justify-between'>
          <Text className='font-bold truncate'>{ product.name }</Text>

        </Box>
        <Box className='flex flex-row justify-between items-center gap-1 py-2'>
          <div className='flex flex-row justify-between items-center gap-1'>

            { product.quantity === 1 ?
              <IconButton size='sm' aria-label='Eliminar' colorScheme='gray' variant='outline' icon={ <HiMinus /> } onClick={ () => { decrease(product._id) } } disabled />
              :
              <IconButton size='sm' aria-label='Restar' icon={ <HiMinus /> } onClick={ () => { decrease(product._id) } } /> }
            <Text className='mx-1 font-bold'>  { product.quantity }</Text>
            <IconButton size='sm' aria-label='Agregar' colorScheme='gray' icon={ <HiPlus /> } onClick={ () => { increase(product._id) } } />
          </div>

          <div className='flex flex-row justify-center items-center gap-1 my-2'>
            <Text className='font-bold'>$ { (product.price * product.quantity).toFixed(2) }</Text>
            { product.quantity === 1 && <IconButton size='sm' aria-label='Eliminar' colorScheme='red' variant='ghost' icon={ <HiTrash /> } onClick={ () => { deleteProduct(product._id) } } /> }
          </div>
        </Box>
      </Td>
      {/* <Td className='hidden md:flex flex-grow' >
        <div className='hidden md:flex flex-row justify-center items-center gap-1'>
          <Text className='font-bold leading-8	'>$ {(product.price * product.quantity).toFixed(2)}</Text>
          {product.quantity === 1 && <IconButton size='sm' aria-label='Eliminar' colorScheme='red' variant='ghost' icon={<HiTrash />} onClick={() => { deleteProduct(product._id) }} />}
        </div>

      </Td> */}
    </Tr>
  )
}

export default OrderRow