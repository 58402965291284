import React from 'react';
import {
  Box,
  Text,
  Tabs, TabList, TabPanels, Tab, TabPanel, Select, Input
} from '@chakra-ui/react';




const OrderInfo = ({ orderInfo, chanegOrderInfo, tables, tabIndex, handleTabsChange }) => {


  return (
    <Box>
      <Tabs isFitted index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab>Mesa</Tab>
          <Tab>Para Llevar</Tab>
          <Tab>A domicilio</Tab>
        </TabList>

        <TabPanels>
          <TabPanel className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>

              <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 lg:w-12/12 items-center mt-2'>
                <Text>Mesa</Text>
                <Select name="table" className='mx-0 md:mx-2' value={orderInfo.table} onChange={(e) => chanegOrderInfo(e)}>
                  <option key='1' value=''></option>
                  {tables ? tables.map(table => (
                    <option key={table._id} value={table.name}>{table.name}</option>
                  )) : null}
                </Select>
              </div>
              <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 lg:w-12/12 items-center mt-2 mx-0  md:mx-4'>
                <Text>Nombre</Text>
                <Input name='name' value={orderInfo.name} placeholder='Nombre' type='text' className='mx-0 md:mx-2' onChange={(e) => chanegOrderInfo(e)} />
              </div>

            <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 lg:w-12/12  items-center mt-2'>
              <Text>Personas</Text>
              <Input name="persons" value={orderInfo.persons} placeholder='# de personas' type='number' className='mx-0 md:mx-2' onChange={(e) => chanegOrderInfo(e)} />
            </div>
          </TabPanel>
          <TabPanel className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
            <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 items-center mt-2'>
              <Text>Nombre</Text>
              <Input name="name" value={orderInfo.name} placeholder='Nombre' type='text' className='mx-0 md:mx-2' onChange={(e) => chanegOrderInfo(e)} />
            </div>
            <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 items-center mt-2'>
              <Text>Teléfono</Text>
              <Input name="phone" value={orderInfo.phone} placeholder='Teléfono' type='text' className='mx-0 md:mx-2' onChange={(e) => chanegOrderInfo(e)} />
            </div>
            <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 items-center mt-2'>
              <Text>Fecha de entrega</Text>
              <Input name="pickUpDate" value={orderInfo.pickUpDate} placeholder='Fecha' type='date' className='mx-0 md:mx-2' onChange={(e) => chanegOrderInfo(e)} />
            </div>
            <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 items-center mt-2'>
              <Text>Hora entrega</Text>
              <Input name="pickUpTime" value={orderInfo.pickUpTime} placeholder='Hora' type='time' className='mx-0 md:mx-2' onChange={(e) => chanegOrderInfo(e)} />
            </div>
          </TabPanel>
          <TabPanel className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
            <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 items-center mt-2'>
              <Text>Nombre</Text>
              <Input name='name' value={orderInfo.name} placeholder='Nombre' type='text' className='mx-0 md:mx-2' onChange={(e) => chanegOrderInfo(e)} />
            </div>
            <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 items-center mt-2'>
              <Text>Teléfono</Text>
              <Input name="phone" value={orderInfo.phone} placeholder='Teléfono' type='text' className='mx-0 md:mx-2' onChange={(e) => chanegOrderInfo(e)} />
            </div>
            <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 items-center mt-2'>
              <Text>Fecha de entrega</Text>
              <Input name="pickUpDate" value={orderInfo.pickUpDate} placeholder='Fecha' type='date' className='mx-0 md:mx-2' onChange={(e) => chanegOrderInfo(e)} />
            </div>
            <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 items-center mt-2'>
              <Text>Hora entrega</Text>
              <Input name="pickUpTime" value={orderInfo.pickUpTime} placeholder='Hora' type='time' className='mx-0 md:mx-2' onChange={(e) => chanegOrderInfo(e)} />
            </div>
            <div className='grid grid-cols-1 md:flex md:flex-row w-12/12 items-center mt-2'>
              <Text>Dirección</Text>
              <Input name="address" value={orderInfo.address} placeholder='Dirección' type='text' className='mx-0 md:mx-2' onChange={(e) => chanegOrderInfo(e)} />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>

  )
}

export default OrderInfo