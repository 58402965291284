export const LIST_CUSTOMERS = 'LIST_CUSTOMERS';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';

export const  SHOW_ALERT = 'SHOW_ALERT';
export const  HIDE_ALERT = 'HIDE_ALERT';

export const LIST_USERS = 'LIST_USERS';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const SUCCESSFUL_SIGNIN = 'SUCCESSFUL_SIGNIN';
export const ERROR_SIGNIN = 'ERROR_SIGNIN';
export const GET_USER_INFO = 'GET_USER_INFO';
export const SUCCESSFUL_LOGIN = 'SUCCESSFUL_LOGIN';
export const ERROR_LOGIN = 'ERROR_LOGIN';
export const LOGOUT = 'LOGOUT';
export const BLOCK = 'BLOCK';
export const GENERATE_RECOVERY = 'GENERATE_RECOVERY';