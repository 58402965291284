import React, { useContext, useEffect } from 'react'
import md5 from 'md5';
import Logo from '../../assets/mi-xula.png'
import
    {
        Button, Input, Box, Text, Alert,
        Link as ChakraLink,
        AlertIcon,
        AlertTitle,
    } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/Auth/authContext';
import AlertContext from '../../contexts/Alert/alertContext';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import
    {
        RESTORE_PASSWORD,
    } from '../../routes/types'
import ToggleButton from '../../components/Header/ToggleButton';

const Login = () =>
{
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const { alerta, showAlert } = alertContext;
    const authContext = useContext(AuthContext)
    const { login, user, message, token, autenticado } = authContext;
    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formData) =>
        {
            let data = {
                email: formData.email,
                password: md5(formData.password)
            }
            await login(data);
        }
    })

    useEffect(() =>
    {

        if (autenticado && user?.role === "SUPER_ADMIN") {
            navigate('/admin');
        }
        if (autenticado && user?.role === "ADMIN") {
            navigate('/admin');
        }
        if (autenticado && user?.role === "MANAGER") {
            navigate('/admin');
        }
        if (autenticado && user?.role === "WAITER") {
            navigate('/orders');
        }
        if (autenticado && user?.role === "CASHIER") {
            navigate('/orders');
        }
    }, [ token, autenticado, navigate ])


    useEffect(() =>
    {

        if (message) {
            // Context de error 
            showAlert(message.title, message.msg, message.categoria)
        }

    }, [ message ])

    return (
        <>
            <div className='w-full gap-1 h-[100vh] flex flex-col justify-center  overflow-x-auto  relative'>
                <Box className='flex flex-row absolute top-0 right-2'> <ToggleButton /></Box>

                <div className='flex flex-col  items-center justify-center w-full gap-1 self-stretch'>
                    <div className='flex flex-col  items-center justify-center gap-1 w-full md:w-8/12 lg:w-4/12'>
                        <form className='w-10/12' onSubmit={ formik.handleSubmit }>
                            <Box className='flex flex-col items-center mb-2'>
                                <img src={ Logo } alt='Logo mi xula' className='w-48' />
                                <Text className='text-center text-2xl  font-semibold '>Mi-Xula Admin</Text>
                            </Box>
                            <div className='w-12/12 mx-8 my-2'>
                                <span className="block text-sm font-medium">Correo</span>
                                <Input id="email" type="text" placeholder="Correo"
                                    value={ formik.values.email }
                                    onChange={ (e) => formik.setFieldValue('email', e.target.value.toLowerCase()) }
                                />
                            </div>
                            <div className='w-12/12 mx-8 my-2'>
                                <span className="block text-sm font-medium">Contraseña</span>
                                <Input id="password" type="password" placeholder="Ingresa tu contraseña"
                                    value={ formik.values.password }
                                    onChange={ (e) => formik.setFieldValue('password', e.target.value) }
                                />

                            </div>
                            <div className='w-12/12 mx-8 mt-8'>
                                <Button colorScheme='green' variant='solid' className='w-full bold uppercase' onClick={ () => formik.handleSubmit() }>Iniciar sesión</Button>
                            </div>
                            <div className='w-12/12 mx-8 mb-8 text-center my-2'>
                                {/* <Link to={`/${SIGNIN}`} className='w-full lg:w-6/12 text-white hover:text-blue-200 active:text-blue-400 py-1 px-2 rounded text-xs mx-1'>Crear cuenta</Link> */ }
                                <Link to={ `/${ RESTORE_PASSWORD }` } className='w-full lg:w-6/12 py-1 px-2 rounded text-xs  mx-1 '>
                                    <ChakraLink>Recuperar contraseña</ChakraLink>
                                </Link>
                            </div>
                        </form>
                    </div>

                </div>
                <Box className='flex flex-row w-full absolute bottom-0'>
                    { alerta !== null ?
                        <Box className='w-full'>
                            <Alert status={ alerta.categoria } variant='solid'>
                                <AlertIcon />
                                <Box>
                                    <AlertTitle>Ocurrio un error</AlertTitle>
                                    { alerta.msg }
                                </Box>
                            </Alert>
                        </Box>
                        : null }
                </Box>

            </div>

        </>
    )
}

export default Login

function initialValues ()
{
    return {
        email: '',
        password: ''
    }
}

function validationSchema ()
{
    return {
        email: Yup.string().required(true),
        password: Yup.string()
            .min(1, 'La contraseña debe de ser Mayor o igual a 6 caracteres')
            .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
            .required(true)
    }
}