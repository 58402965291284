import React, { useState, useEffect, useContext } from 'react'
import { Text, Box, IconButton, Button, Stack, Avatar } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineDeliveryDining, MdOutlineShoppingBag, MdOutlineChairAlt } from 'react-icons/md'
import { HiArrowNarrowLeft } from 'react-icons/hi';
import OrderResume from '../../components/Pos/OrderResume';
import OrderSummary from '../../components/Pos/OrderSummary';
import AmountSummary from '../../components/Pos/AmountSummary';
import { get_order } from '../../utils/apis/orders'
import { localOrder } from '../../utils/functions/generateLocalCart';
import { getTotal, getIva } from '../../utils/functions/getTotal';
import { post_transaction } from '../../utils/apis/transactions';
import AlertContext from '../../contexts/Alert/alertContext';
import { AlertaPermisos } from '../../components/Alerta/AlertaPermisos';

const PosDetail = () => {
  const alertContext = useContext(AlertContext);
  const {alerta, showAlert} = alertContext;
  const navigate = useNavigate();
  let { id } = useParams();
  const [order, setOrder] = useState([]);
  const [paymentType, setPaymentType] = useState('efectivo')
  const [orderDetail, setOrderDetail] = useState(null)
  const [orderInfo, setOrderInfo] = useState({
    table: '',
    persons: '',
    name: '',
    phone: '',
    pickUpDate: '',
    pickUpTime: '',
    address: ''
  });
  const [subTotal, setSubtotal] = useState(0.00);
  const [ivaAmount, setIvaAmount] = useState(0.00);
  const [total, setTotal] = useState(0.00);
  const [desc, setDesc] = useState(0);
  const calcSummary = (items) => {
    const orderTotal = getTotal(items);
    const ivaAmount = getIva(orderTotal)
    setSubtotal(orderTotal.toFixed(2));
    setIvaAmount(ivaAmount);
    setTotal(orderTotal.toFixed(2))
  }

  const calcSummaryDesc = (items, desc) => {
    const orderTotal = getTotal(items);
    const percentage = 1 - ( Number(desc) / 100);
    console.log(1-percentage)
    if (desc <= 100) {
      const ivaAmount = getIva((orderTotal * percentage))
      setIvaAmount(ivaAmount);
      setTotal((orderTotal * percentage).toFixed(2))
    }else{
      showAlert('Ocurrio Algo', 'El descuento no puede ser mayor al total', 'info')
    }
  }

  const getOrder = async (id) => {
    const res = await get_order(id);
    setOrderDetail(res.results)
    setOrderInfo(res.results.orderInfo);
    const items = await localOrder(res.results.orderProducts);
    setOrder(items);
    calcSummary(items)

  }

  const goBack = () => {
    navigate(-1);
  }
  useEffect(() => {
    getOrder(id);

  }, [id]);

  useEffect(() => {
    calcSummaryDesc(order, desc)
  }, [desc])

  const addTransaction = async () => {
    const data = {
      orderInfo: orderDetail.orderInfo,
      status: 'paid',
      orderType: orderDetail.orderType,
      orderProducts: order,
      methodPayment: paymentType,
      subtotal: subTotal,
      tax: ivaAmount,
      discount: desc,
      total: total,
      dateOrder: orderDetail.date,
      orderId: orderDetail._id,
      waiter: orderDetail.user._id
    }
    console.log(data)
    const res = await post_transaction(data);
    if (res.msg === 'done') {
      goBack()
    }
  }

  return (
    <>
      <div className='flex flex-row items-center justify-start gap-2'>
        <div className='flex flex-row items-center justify-start gap-2' onClick={() => goBack()} >
          <IconButton aria-label='Search database' icon={<HiArrowNarrowLeft />} />
          <Text>Regresar</Text>
        </div>
      </div>
      <Box className='grid grid-cols-1 justify-start items-start md:items-start gap-1' p={4} mt={2} w='100%' borderWidth='1px' borderRadius='lg'>
        <Box className='flex flex-row justify-between'>
          <Box className='flex flex-row items-center'>
            {orderDetail?.orderType === 'local' && <MdOutlineChairAlt className='text-2xl' />}
            {orderDetail?.orderType === 'delivery' && <MdOutlineDeliveryDining className='text-2xl' />}
            {orderDetail?.orderType === 'pickup' && <MdOutlineShoppingBag className='text-xl' />}
            {orderDetail?.orderType === 'local' && <Text className='text-md mx-2' noOfLines={2} >{orderInfo.table}</Text>}
            {orderDetail?.orderType === 'delivery' && <Text className=' text-md mx-2' noOfLines={2} >Para entregar</Text>}
            {orderDetail?.orderType === 'pickup' && <Text className=' text-md mx-2' noOfLines={2} >Para llevar</Text>}
          </Box>
          <Box className='flex flex-row items-center gap-1'>
            <Text className='text-xs'>Atendido por:</Text>
            <Avatar name={orderDetail?.user?.name} size='xs' />
            <Text>{orderDetail?.user?.name}</Text>
          </Box>
        </Box>
        <Box className='grid grid-cols-1 md:flex flex-row'>
          <Text className='font-bold text-md md:text-lg' noOfLines={1} >Pedido</Text>
          <Text className='font-bold text-md md:text-lg' noOfLines={1} >#{id}</Text>
        </Box>

      </Box>
      <div className='flex flex-col-reverse md:flex-row gap-1 justify-between'>
        <Box width='100%'>
          <OrderResume order={order} />

          <AmountSummary subTotal={subTotal} ivaAmount={ivaAmount} total={total} setDesc={setDesc} desc={desc} addTransaction={addTransaction} />
        </Box>
        <Box className='w-12/12 md:w-6/12 lg:w-4/12 flex flex-col items-stretch'>
          <OrderSummary orderInfo={orderInfo} orderDetail={orderDetail} />
          <Box className='w-12/12' p={2} mt={2} borderWidth='1px' borderRadius='lg'>
            <Text className='text-xl font-bold mb-3'>Forma de pago</Text>
            <Stack direction='row' spacing={2} align='center'>
              <Button colorScheme='green' variant={paymentType === 'efectivo' ? 'solid' : 'outline'} width='100%' onClick={() => setPaymentType('efectivo')}>
                Efectivo
              </Button>
              <Button colorScheme='green' variant={paymentType === 'tarjeta' ? 'solid' : 'outline'} width='100%' onClick={() => setPaymentType('tarjeta')}>
                Tarjeta
              </Button>

            </Stack>
          </Box>
        </Box>

      </div>
      {alerta && <AlertaPermisos alerta={alerta} />}
    </>
  )
}

export default PosDetail