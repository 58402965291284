import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'

import {
  Button, Table,
  Thead,
  Tbody,
  Tag,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'
import Loading from '../../../components/Loading/Loading';
import { get_categories } from '../../../utils/apis/categories';

const Categories = () => {
  const navigate = useNavigate()
  const [items, setItems] = useState();

  useEffect(() => {
    (async () => {
      const res = await get_categories();
      console.log(res)
      setItems(res.results)
    })()
  }, [])

  const editCategory = (id) => {
    navigate(`/admin/categories/${id}`);
  }



  return (
    <>
      <div className='grid grid-cols-1 md:flex md:flex-row justify-between items-start md:items-center gap-1'>
        <h2 className='text-3xl font-semibold'>Categorias</h2>
        <Button colorScheme='green' onClick={() => navigate('/admin/categories/new')}>Crear categoría</Button>
      </div>
      {!items && <Loading />}
      {items && <div className='my-2'>
        <TableContainer>
          <Table variant='striped' colorScheme='gray'>
            <Thead>
              <Tr>
                <Th>Nombre</Th>
                <Th>Estado</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items ? items.map((item, i) => (
                <Tr key={i}>
                  <Td onClick={() => editCategory(item._id)}><span className='font-semibold'>{item?.name}</span></Td>
                  <Td>{item?.status ? <Tag variant='outline' colorScheme='green'>visible</Tag> :<Tag variant='outline' colorScheme='red'>oculto</Tag>}</Td>
                </Tr>
              )) : null}
            </Tbody>
          </Table>
        </TableContainer>
      </div>}
    </>
  )
}

export default Categories


