export const getTotal = (products) => {
  let temporalTotal = 0.00
  for (const product of products) {
    temporalTotal += product.price * product.quantity
  }
  return temporalTotal
}

export const getIva = (amount) => {
  return  (amount - (amount / 1.16)).toFixed(2)
}