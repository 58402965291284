import React from 'react'
import {
  Tag,
  Tr,
  Td,
} from '@chakra-ui/react'

const ProductRow = ({ item, editProduct, updateProduct }) => {
  return (
    <Tr key={item._id}>
      <Td onClick={() => editProduct(item._id)}>

        <span className='font-semibold'>{item?.name}</span>
      </Td>
      <Td>
        <span>${item?.price.toFixed(2)}</span>
      </Td>
      <Td>
       {item.inventoryStatus ?  <span className={`font-semibold ${item?.qty <= 5 && 'text-yellow-500'}  ${item?.qty === 0 && 'text-red-500'}`} >{item?.qty}</span>
       : null}
      </Td>
      <Td>{item?.status ? <Tag variant='outline' colorScheme='green' onClick={() => updateProduct(item._id, false)}>visible</Tag> : <Tag variant='outline' onClick={() => updateProduct(item._id, true)} colorScheme='red'>oculto</Tag>}</Td>
      <Td>{item?.category?.name}</Td>

    </Tr>
  )
}

export default ProductRow