import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const Row = ({colName, colInfo}) => {
  return (
    <Box className='grid grid-cols-2 gap-1 w-full py-2 px-1'>
      <Text>{colName}</Text>
      <Text className='text-right'>{colInfo}</Text>
    </Box>
  )
}

export default Row