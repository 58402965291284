/* eslint-disable import/no-anonymous-default-export */
import { useReducer } from 'react';
import userReducer from './userReducer';
import userContext from './userContext';
import { get_users, post_user, delete_user, update_user, restore_user } from '../../utils/apis/users';
import {
    LIST_USERS,
    ADD_USER,
    DELETE_USER,
    UPDATE_USER
} from '../types';

const UserState = props => {
    const initialState = {
        total:0,
        list: null,
        loading: false,
        message: null
    }

    const [state, dispatch] = useReducer(userReducer, initialState);

    const getUsersList = async (data) => {
        try {
            const res = await get_users(data)
            if (res.length !== 0) {

                dispatch({
                    type: LIST_USERS,
                    payload: res.results
                })
            } else {
                
            }
        } catch (error) {

           
        }


    }
    const postNewUser = async (data)=>{
        try {
            const res = await post_user(data)

            if (res.inserted_id) {

                dispatch({
                    type: ADD_USER
                })
            } else {
                
            }
        } catch (error) {

           
        }
    }

    const updateUser = async (data)=>{
        try {
            await update_user(data)
            dispatch({
                type: UPDATE_USER
            })
        } catch (error) {

           
        }
    }

    const deleteUser = async (id)=>{
        console.log(id)
        try {
            await delete_user(id)
            dispatch({
                type: DELETE_USER
            })
        } catch (error) {

           
        }
    }

    const restoreUser = async (id)=>{
        console.log(id)
        try {
            await restore_user(id)
            dispatch({
                type: UPDATE_USER
            })
        } catch (error) {

           
        }
    }

    return (
        <userContext.Provider
            value={{
                total:state.total,
                list: state.list,
                loading: state.loading,
                message: state.message,
                getUsersList,
                postNewUser,
                deleteUser,
                updateUser,
                restoreUser
            }}
        >
            {
                props.children
            }
        </userContext.Provider>
    )

}


export default UserState