import React, { useContext, useEffect, useState } from "react";
import Logo from "../../assets/mi-xula.png";
import {
  Button,
  Input,
  Box,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import AlertContext from "../../contexts/Alert/alertContext";
import AuthContext from "../../contexts/Auth/authContext";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ToggleButton from "../../components/Header/ToggleButton";
const Recovery = () => {
  const navigate = useNavigate();
  const [ok,setOk] = useState(false);
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;
  const authContext = useContext(AuthContext);
  const { generateRecovery, message } = authContext;

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formData) => {
      let data = {
        email: formData.email,
      };
     const test= await generateRecovery(data); 
    if(test){
      setOk(true)
    }
    },
  });

  const goBack = async () => {
    navigate(-1);
  };

  useEffect(() => {
    if (message) {
      // Context de error
      showAlert(message.title, message.msg, message.categoria);
    }
  }, [message]);

  return (
    <div>
      <div className="w-full gap-1 h-[100vh] flex flex-col justify-center  overflow-x-auto  relative">
        <Box className="flex flex-row absolute top-0 right-2">
          <ToggleButton />
        </Box>

        <div className="flex flex-col  items-center justify-center w-full gap-1 self-stretch">
          <div className="flex flex-col  items-center justify-center gap-1 w-full md:w-8/12 lg:w-4/12">
          <img src={Logo} alt="Logo mi xula" className="w-40" />
{!ok ?             <form className="w-10/12" onSubmit={formik.handleSubmit}>
              <Box className="flex flex-col items-center mb-2">
          
                <Text className="text-center text-2xl  font-semibold ">
                  Recupera tu cuenta
                </Text>
                <Text className="text-center text-md my-2">
                  Ingresa tu correo electrónico para buscar tu cuenta.
                </Text>
              </Box>
              <div className="w-12/12 mx-8 my-2">
                <span className="block text-sm font-medium">Correo</span>
                <Input
                  id="email"
                  type="text"
                  placeholder="Correo"
                  value={formik.values.email}
                  onChange={(e) =>
                    formik.setFieldValue("email", e.target.value.toLowerCase())
                  }
                />
              </div>
              <Box className="grid grid-cols-2 mx-8 mt-8 gap-2">
                <Button
                  colorScheme="gray"
                  variant="solid"
                  className="w-full uppercase"
                  onClick={() => goBack()}
                >
                  Regresar
                </Button>
                <Button
                  colorScheme="green"
                  variant="solid"
                  className="w-full uppercase"
                  onClick={() => formik.handleSubmit()}
                >
                  Recuperar
                </Button>
              </Box>
            </form>:
            <Box className="my-2 mx-2">
              <Text className="my-2 mx-2 text-center">Se ha enviado un correo de recuperación, siga las instrucciones para recuperar su contraseña</Text>
              <Button
                  colorScheme="green"
                  variant="solid"
                  className="w-full uppercase"
                  onClick={() => goBack()}
                >
                  Regresar
                </Button>
            </Box>}
          </div>
        </div>
        <Box className="flex flex-row w-full absolute bottom-0">
          {alerta && alerta.msg !== "done" ? (
            <Box className="w-full">
              <Alert status={alerta.categoria} variant="solid">
                <AlertIcon />
                <Box>
                  <AlertTitle>Ocurrio un error</AlertTitle>
                  {alerta.msg}
                </Box>
              </Alert>
            </Box>
          ) : null}
        </Box>
      </div>
    </div>
  );
};

export default Recovery;

function initialValues() {
  return {
    email: "",
  };
}

function validationSchema() {
  return {
    email: Yup.string().required(true),
  };
}
