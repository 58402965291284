import React from 'react'
import {useColorMode} from '@chakra-ui/color-mode'
import { IconButton} from '@chakra-ui/react'
import {HiMoon, HiSun} from 'react-icons/hi'

const ToggleButton = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <IconButton variant='ghost' onClick={toggleColorMode} icon={colorMode === 'light' ? <HiMoon />: <HiSun />}/>

  )
}

export default ToggleButton

