import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const BoxSummary = ({title, value,color}) => {
  return (
    <Box w='100%' px={4} mt={2} borderWidth='1px' borderRadius='lg' h='80px' bg={`${color}.100`} borderColor={`${color}.100`}>
      <Text className='font-bold uppercase text-xs md:text-sm mt-2' noOfLines={1} color={`${color}.600`}>{title}</Text>
      <Text className='font-bold text-lg md:text-2xl text-right' color={`${color}.600`} >{value}</Text>
    </Box>
  )
}

export default BoxSummary