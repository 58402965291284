/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext, useEffect } from 'react'
import { SocketContext } from '../contexts/Socket/socket';
import AlertContext from '../contexts/Alert/alertContext';
import { Navigate } from 'react-router-dom'
import { useColorMode } from '@chakra-ui/color-mode'
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import AuthContext from '../contexts/Auth/authContext';
import ChangePassword from '../components/Modal/ChangePassword';
import { Alerta } from '../components/Alerta/Alerta';

const PrivateRoute = ({ children }) => {
    const socket = useContext(SocketContext);
    const alertContext = useContext(AlertContext);
    const { alerta, showAlert } = alertContext;
    const { colorMode } = useColorMode();
    const authContext = useContext(AuthContext);
    const [showText, setShowText] = useState(true);
    const { token, user } = authContext;
    const [showSidebar, setShowSidebar] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const show = () => {
        setShowSidebar(!showSidebar)
    }

    useEffect(() => {
        socket.on('disconnect', (payload) => {
            showAlert('Desconectado', 'error')
        })
    }, [socket])



    return token ? <div className="flex flex-col w-screen h-[calc(100vh-4rem)]">
       {alerta?.msg === 'Desconectado' &&  <Alerta alerta={alerta} colorMode={colorMode} />}
        <Header show={show} showSidebar={showSidebar} setShowChangePassword={setShowChangePassword} setShowText={setShowText} showText={showText} />

        <div className='flex flex-row print:h-full  z-0'>
            <Sidebar showSidebar={showSidebar} show={show} showText={showText} />
            <div className={` h-[calc(100vh-4rem)] z-0 print:w-full  print:border-0 print:h-full print:overflow-hidden overflow-auto border-l ${showText ? 'w-10/12 lg:10/12' : 'w-full lg:w-full'} pt-4 px-8  ${colorMode === 'light' ? 'border-gray-100' : 'border-gray-700'}`}>
                {children}
            </div>


        </div>
        <ChangePassword id={user._id} show={showChangePassword} setShow={setShowChangePassword} />

    </div>

        : <Navigate to={'/login'} replace />
}

export default PrivateRoute
