import { map, size, filter } from 'lodash'
import { CART } from '../constants'
export const get_all_orders = async (data) => {
    const token = await localStorage.getItem('token');
    try {
        const url = `${process.env.REACT_APP_API_URL}/v1/api/orders`;
        const params = {
            method: 'GET',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json()
        return result

    } catch (error) {
        console.log(error)
        return null
    }
}

export const get_order = async (id) => {
    const token = await localStorage.getItem('token');
    try {
        const url = `${process.env.REACT_APP_API_URL}/v1/api/orders/${id}`;
        const params = {
            method: 'GET',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        };

        const response = await fetch(url, params);
        const result = await response.json()
        return result

    } catch (error) {
        console.log(error)
        return null
    }
}

export const post_order = async (data) => {
    const token = await localStorage.getItem('token');
    try {
        const url = `${process.env.REACT_APP_API_URL}/v1/api/orders`;
        const params = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body:JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json()
        return result

    } catch (error) {
        console.log(error)
        return null
    }
}

export const update_order = async (id,data) => {
    const token = await localStorage.getItem('token');
    try {
        const url = `${process.env.REACT_APP_API_URL}/v1/api/orders/${id}`;
        const params = {
            method: 'PATCH',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body:JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json()
        return result

    } catch (error) {
        console.log(error)
        return null
    }
}

export const cancel_order = async (id ,data) => {
    const token = await localStorage.getItem('token');
    try {
        const url = `${process.env.REACT_APP_API_URL}/v1/api/orders/${id}`;
        const params = {
            method: 'DELETE',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body:JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json()
        return result

    } catch (error) {
        console.log(error)
        return null
    }
}

export async function getProductCartApi () {
    try {
        const cart = await localStorage.getItem(CART);
        if (!cart) return [];
        else return JSON.parse(cart)
    } catch (error) {
        return null
    }
}

export async function addProductCartApi (_id, quantity) {

    try {
        const cart = await getProductCartApi();
        if (!cart) throw "Error al obtener el carrito";

        if (size(cart) === 0) {
            cart.push({
                _id,
                quantity,
            })
        } else {
            let found = false;
            map(cart, (product) => {
                if (product._id === _id) {
                    product.quantity += quantity
                    found = true;
                    return product;
                }
            });
            if (!found) {
                cart.push({
                    _id,
                    quantity,

                });
            }
        }
        await localStorage.setItem(CART, JSON.stringify(cart));
        return true;
    } catch (error) {
        return false
    }

}

export async function deleteProductCartApi (_id) {
    try {
        const cart = await getProductCartApi();
        const newCart = filter(cart, (product) => {
            return product._id !== _id;
        })
        await localStorage.setItem(CART, JSON.stringify(newCart))
        return true
    } catch (error) {
        return null
    }
}

export async function clearProductCartApi () {
    try {
        await localStorage.removeItem(CART)
        return true
    } catch (error) {
        return null
    }
}

export async function increaseProductCartApi (_id) {
    try {
        const cart = await getProductCartApi();
        map(cart, (product) => {

            if (product._id === _id) {
                return (product.quantity += 1)
            }
        })

        await localStorage.setItem(CART, JSON.stringify(cart))
        return true
    } catch (error) {
        return null
    }

}

export async function decreaseProductCartApi (_id) {
    let isDelete = false
    try {
        const cart = await getProductCartApi();
        map(cart, (product) => {
            if (product._id === _id) {
                if (product.quantity === 1) {
                    isDelete = true
                    return null
                } else {
                    return (product.quantity -= 1)
                }

            }
        })

        if (isDelete) {
            await deleteProductCartApi(_id)
        } else {
            await localStorage.setItem(CART, JSON.stringify(cart))
        }


        return true
    } catch (error) {
        return null
    }

}