import React, { useState, useEffect, useContext } from 'react'
import { SocketContext } from '../../contexts/Socket/socket';
import { useColorMode } from '@chakra-ui/color-mode';
import {Text} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Box } from '@chakra-ui/react'
import { HiMenuAlt1, HiArrowSmLeft, HiUserCircle, HiStatusOffline, HiStatusOnline } from "react-icons/hi";
import ToggleButton from './ToggleButton'
import Logo from '../../assets/mi-xula.png'
import AuthContext from '../../contexts/Auth/authContext';

const Header = ({setShowChangePassword, showSidebar, show, setShowText, showText}) => {
  const socket = useContext(SocketContext)
  const { colorMode } = useColorMode();
  const [statusConnection, setStatusConnection] = useState(false)
  const [showNav, setShowNav] = useState(false);
  const authContext = useContext(AuthContext)
  const { user, logout } = authContext;
  const toggleNav = () => {
    setShowNav(!showNav)
  }

  const openChangePassword = () => {
    setShowChangePassword(true);
    toggleNav()

  }

  useEffect(() => {
    socket.on('user-connected', (payload) => {
      setStatusConnection(true)
      document.title = 'Mi Xula Dashboard Admin'
    })
    socket.on('disconnect', (payload) => {
      setStatusConnection(false)
    })

  }, [socket])



  return (
    <header className='h-16 flex flex-row items-center justify-between border-b print:hidden '>
     <Box className='flex flex-row items-center justify-start'>
     <img src={Logo} className='mx-2 object-contain	w-16 h-16 p-1' alt='Logo' onClick={()=>{setShowText(!showText);}}/>
     {statusConnection ? <HiStatusOnline color='green'/> : <HiStatusOffline  color='red'/> }
     {statusConnection ? <Text className='hidden md:block text-xs mx-1 font-thin'>Conectado</Text> : <Text className='hidden md:block text-xs mx-1 font-thin'>Sin conexión</Text> }
     </Box>
      <div className='w-4/12 lg:w-2/12 flex flew-row justify-end items-center'>
        <ToggleButton />
        <div className="p-2">
          <div className="group inline-block relative">
            <Button variant='ghost' colorScheme='gray' onClick={toggleNav} className='flex flex-row gap-2 items-center'>
              <HiUserCircle className="cursor-pointer left-4 text-4xl" />
              <Box className='flex flex-col justify-start items-start'>
                <Text className="mr-1 lg:flex">{user?.name || 'hola'}</Text>
                <Text  className='text-xs mt-1'>{user?.role}</Text>
              </Box>
            </Button>
            <div className={`z-10 shadow-xl shadow-slate-500/50 absolute ${showNav ? 'group-hover:block' : 'hidden'} text-gray-700 pt-1 w-100 right-0 ${colorMode === 'light' ? 'bg-gray-50' : 'bg-gray-800'} rounded-md`}>
              <ul className="text-left w-80">
                <li className="w-full"><Link className={`${colorMode === 'light' ? 'text-gray-800' : 'text-white'}  hover:text-green-700 py-2 px-4 block whitespace-no-wrap w-full`} onClick={openChangePassword}>Cambiar contraseña</Link></li>
                <li className="w-full"><Link className={`${colorMode === 'light' ? 'text-gray-800' : 'text-white'}  hover:text-green-700 py-2 px-4 block whitespace-no-wrap`} to="/" onClick={logout}>Cerrar sesión</Link></li>
              </ul>
            </div>
          </div>

        </div>

      </div>


    </header>
  )
}

export default Header