import React from 'react'
import {useColorMode} from '@chakra-ui/color-mode'
import { NavLink } from 'react-router-dom';
import {Text, Box} from '@chakra-ui/react'

const MenuItem = ({title, icon, link, showText}) => {
  const {colorMode} = useColorMode()
  return (
    <NavLink  to={link} className={`${colorMode === 'light' ? 'text-gray-800':'text-white'} py-2 px-5 hover:bg-gray-800 hover:rounded-xl hover:text-gray-100 flex flex-col gap-1 justify-center h-12`}>
      <Box className='flex flex-row gap-1 items-stretch justify-sta'>
      <Text>{icon}</Text> 
      {showText && <Text className='text-sm hidden md:block'>{title}</Text>}
      </Box>
    </NavLink>
  )
}

export default MenuItem