import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from "../pages/Auth/Login";
import Recovery from '../pages/Auth/Recovery';
import ResetPassword from "../pages/Auth/ResetPassword";
import Home from "../pages/Home/Home";
import Users from "../pages/Users/Users";
import Products from '../pages/Products/Products/Products'
import Product from '../pages/Products/Products/Product'
import Categories from '../pages/Products/Categories/Categories'
import Category from '../pages/Products/Categories/Category'
import Tables from '../pages/Settings/Tables/Tables'
import Orders from '../pages/Orders/Orders'
import Order from '../pages/Orders/Order'
import NotFound from "../pages/NotFound/NotFound";
import PrivateRoute from "./PrivateRoutes";
import { ColorModeScript } from '@chakra-ui/color-mode'
import { ChakraProvider } from '@chakra-ui/react'
import theme from '../theme'
import Dashboard from "../pages/Dashboard/Dashboard";
import PosDetail from "../pages/POS/PosDetail";
import Reports from "../pages/Reports/Reports";
import Daily from "../pages/Reports/Daily";
import Inventory from "../pages/Reports/Inventory";

const AppRoute = () => {
  return (
    <ChakraProvider theme={theme} >
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <Routes>
          <Route exact path="/*" element={<PrivateRoute><NotFound /></PrivateRoute>} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/restore_password" element={<Recovery />} />
          <Route exact path="/reset_password/:id" element={<ResetPassword />} />
          <Route exact path="/admin">
            <Route exact path="/admin" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route exact path="/admin/products" element={<PrivateRoute><Products /></PrivateRoute>} />
            <Route exact path="/admin/products/new" element={<PrivateRoute><Product /></PrivateRoute>} />
            <Route exact path="/admin/products/:id" element={<PrivateRoute><Product /></PrivateRoute>} />
            <Route exact path="/admin/categories" element={<PrivateRoute><Categories /></PrivateRoute>} />
            <Route exact path="/admin/categories/new" element={<PrivateRoute><Category /></PrivateRoute>} />
            <Route exact path="/admin/categories/:id" element={<PrivateRoute><Category /></PrivateRoute>} />
          </Route>
          <Route exact path="/pos"  >
            <Route exact path="/pos" element={<PrivateRoute><Orders /></PrivateRoute>} />
            <Route exact path="/pos/:id" element={<PrivateRoute><PosDetail /></PrivateRoute>} />
          </Route>
          <Route exact path="/orders"  >
            <Route exact path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
            <Route exact path="/orders/new" element={<PrivateRoute><Order /></PrivateRoute>} />
            <Route exact path="/orders/:id" element={<PrivateRoute><Order /></PrivateRoute>} />
          </Route>
          <Route exact path="/reports"  >
            <Route exact path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
            <Route exact path="/reports/sales" element={<PrivateRoute><Daily /></PrivateRoute>} />
            <Route exact path="/reports/inventory" element={<PrivateRoute><Inventory /></PrivateRoute>} />

          </Route>

          <Route exact path="/settings"  >
            <Route exact path="/settings/tables" element={<PrivateRoute><Tables /></PrivateRoute>} />

          </Route>
          <Route exact path="users" element={<PrivateRoute><Users /></PrivateRoute>} />


          <Route exact path="/home" element={<Home />} />
          <Route exact path="/users" element={<Users />} />

        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default AppRoute;
