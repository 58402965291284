import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Input,
  FormControl,
  Button, Table,
  Thead,
  Tbody,
  Tag,
  Tr,
  Th,
  Td,
  TableContainer,
  Box
} from '@chakra-ui/react'
import Loading from '../../../components/Loading/Loading';
import { HiViewGrid, HiViewList } from 'react-icons/hi'
import { get_tables, post_table, update_table } from '../../../utils/apis/tables';

const Tables = () => {
  const navigate = useNavigate();
  const [view, setView] = useState('grid');
  const [items, setItems] = useState();

  useEffect(() => {
    (async () => {
      await listTables()
    })()
  }, [])

  const listTables = async () => {
    const res = await get_tables();
    setItems(res.results)
  }
  const resteFormikValue = async () => {
    formik.resetForm()
  }
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      console.log(data)
      const table = {
        name: data.name,
        status: true
      }
      await post_table(table)

      resteFormikValue()
      listTables()
    }
  })

  const updatTable = async (id, newStatus) => {
    await update_table(id, { status: newStatus })
    listTables()
  }




  return (
    <>
      <div className='grid grid-cols-1 md:flex md:flex-row justify-between items-center gap-2'>
        <h2 className='text-3xl font-semibold w-full'>Mesas</h2>
        <div className='flex flex-row gap-3 w-full md:w-full'>
          <FormControl className='grid grid-cols-1 md:flex md:flex-row  gap-2 w-full' onSubmit={formik.handleSubmit}>

            <Input className='w-full' placeholder='Nombre de la mesa' value={formik.values.name} onChange={(e) => formik.setFieldValue('name', e.target.value)} />
            <Button className='w-full' colorScheme='green' onClick={() => formik.handleSubmit()}>Agregar mesa</Button>
          </FormControl>

        </div>
      </div>
      <div className='flex flex-row items-end justify-end gap-2 my-4'>
        <Button variant={view === 'table' ? "solid" : "ghost"} colorScheme='gray' onClick={() => setView('table')}>
          <HiViewList />
        </Button>
        <Button variant={view === 'grid' ? "solid" : "ghost"} colorScheme='gray' onClick={() => setView('grid')}>
          <HiViewGrid />
        </Button>
      </div>
      {!items && <Loading />}
      {items && <div className='my-2'>
        {view === 'table' && <TableContainer>
          <Table variant='striped' colorScheme='gray'>
            <Thead>
              <Tr>
                <Th>Nombre</Th>
                <Th>Visibilidad</Th>
                <Th>Estado</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items ? items.map((item, i) => (
                <Tr key={i}>
                  <Td ><span className='font-semibold'>{item?.name}</span></Td>
                  <Td>{item?.status ? <Tag variant='outline' onClick={() => updatTable(item._id, false)} colorScheme='green'>visible</Tag> : <Tag variant='outline' colorScheme='red' onClick={() => updatTable(item._id, true)}>oculto</Tag>}</Td>
                  {item?.status ?
                    <Td>{item?.serviceStatus ? <Tag variant='outline' colorScheme='red'>Ocupada</Tag> : <Tag variant='outline' colorScheme='green'>Disponible</Tag>}</Td>
                    :
                    <Td><Tag variant='outline' colorScheme='gray'>No asignable</Tag></Td>
                  }
                </Tr>
              )) : null}
            </Tbody>
          </Table>
        </TableContainer>}

        {view === 'grid' && <div className='flex flex-row gap-2 flex-wrap justify-around'>
          {items ? items.map((item, i) => (

            <Box key={i} maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' p={4} className="w-full sm:w-auto md:w-4/12 lg:w-3/12">
              <Box ><span className='font-semibold'>{item?.name}</span></Box>
              <Box className='flex flex-row gap-2'>
                <Box>{item?.status ? <Tag variant='outline' onClick={() => updatTable(item._id, false)} colorScheme='green'>visible</Tag> : <Tag variant='outline' colorScheme='red' onClick={() => updatTable(item._id, true)}>oculto</Tag>}</Box>

                {item?.status ?
                  <span>{item?.serviceStatus ? <Tag variant='outline' colorScheme='red'>Ocupada</Tag> : <Tag variant='outline' colorScheme='green'>Disponible</Tag>}</span>

                  :
                  <span><Tag variant='outline' colorScheme='gray'>No asignable</Tag></span>
                }
              </Box>
            </Box>

          )) : null}

        </div>
        }

      </div>}
    </>
  )
}

export default Tables


function initialValues () {
  return {
    name: '',
  }
}

function validationSchema () {
  return {
    name: Yup.string().required(true),
  }
}