import React, { useEffect, useContext } from 'react'
import
{
  Box, Text,
  Button,
  Divider,
  Table,
  Thead,
  TableContainer,
  Tbody
} from '@chakra-ui/react'
import OrderRow from './OrderRow'
import { deleteProductCartApi, increaseProductCartApi, decreaseProductCartApi, clearProductCartApi } from '../../../utils/apis/orders'
import EmptyOrder from '../../Empties/EmptyOrder'
import { get_product } from '../../../utils/apis/products'
import { localOrder } from '../../../utils/functions/generateLocalCart'
import AuthContext from '../../../contexts/Auth/authContext';
import AlertContext from '../../../contexts/Alert/alertContext';
import Loading from '../../Loading/Loading'
const Summary = ({
  order,
  reloadCart,
  orderProducts,
  setOrderProducts,
  setLoadingProducts,
  loadingProducts,
  getTotal,
  setTotalPayment,
  authorization
}) =>
{
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const alertContext = useContext(AlertContext);
  const { showAlert } = alertContext;
  useEffect(() =>
  {

    (async () =>
    {

      const productTemp = await localOrder(order)
      setOrderProducts(productTemp)
      setLoadingProducts(false)
      setTotalPayment(getTotal(productTemp))

    })()
  }, [ order ]);

  const deleteProductCart = async (id) =>
  {
    if ((user.role === 'SUPER_ADMIN') || (user.role === 'ADMIN')) {
      const response = await deleteProductCartApi(id)
      if (response) {
        reloadCart(true)
      }
    }
    if ((authorization && user.role === 'WAITER') || authorization && user.role === 'CASHIER') {
      showAlert("Se requiere permisos", 'Contacta al gerente', 'error')
    }

    if (!authorization) {
      const response = await deleteProductCartApi(id)
      if (response) {
        reloadCart(true)
      }
    }

  }
  const increase = async (id) =>
  {
    await increaseProductCartApi(id)
    reloadCart(true)
  }

  const decrease = async (id) =>
  {
    console.log('click')
    if ((user.role === 'SUPER_ADMIN') || (user.role === 'MANAGER') || (user.role === 'ADMIN')) {
      await decreaseProductCartApi(id)
      reloadCart(true)
    }
    if (authorization && user.role === 'CASHIER') {
      showAlert("Se requiere permisos", 'Contacta al gerente', 'error')
    }

    if (!authorization) {
      await decreaseProductCartApi(id)
      reloadCart(true)
    }

  }

  const clearCart = async () =>
  {
    clearProductCartApi();
    reloadCart(true)
  }
  return (

    <Box w='100%' p={ 4 } mb={ 2 } borderWidth='1px' borderRadius='lg' >
      <div className='flex flex-row justify-between items-center'>
        <Text mb='8px' fontSize='lg' className='font-bold uppercase'>Resumen de la orden</Text>
        { orderProducts.length !== 0 && !authorization && <Button variant='ghost' colorScheme='red' onClick={ clearCart }>Eliminar orden</Button> }
      </div>
      <Divider />
      <TableContainer>
        <Table size='sm'>
          <Thead></Thead>
          <Tbody>
            { orderProducts.length !== 0 && orderProducts.map(item => (
              <OrderRow authorization={ authorization } user={ user } key={ item._id } product={ item } increase={ increase } decrease={ decrease } deleteProduct={ deleteProductCart } />
            )) }
            { orderProducts.length === 0 && !loadingProducts &&
              <EmptyOrder />
            }
          </Tbody>
        </Table>
      </TableContainer>
      { loadingProducts && <Loading /> }

    </Box>


  )
}

export default Summary